import React, { useState, useEffect, useRef } from "react";
import {withStyles} from "@material-ui/core";
//import {  localStorageGet } from './../../ui-utils/commons';
const styles = theme => ({
  participant:{
  //  marginBottom:'5%',
   height:'280px'
  },
  identity:{
    display:'flex',
    justifyContent:'flex-end',
    fontFamily:'PoppinsBold',
    fontSize:'16px',
    lineHeight:'24px',
    height:'31px',
    letterSpacing:0,
    background:'white',
    width: '99%',
    position:'relative',
    bottom:'35px',
    alignItems: 'center'
  },
  name:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    fontSize:'20px',
    width:"100px",
    height:"100px",
    borderRadius: "50%",
    background: "#029cab",
    color: "#fff",
  },
  nameContainer: {
    color: 'red',
    height: 'inherit',
    display: 'flex',
    fontSize: '20px',
    background: '#202124',
    alignItems: 'center',
    justifyContent: 'center',
  }
});
const Participant = ({ participant,classes,inactiveAudio, length, borderm, view, mute, showVideo,local }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const videoRef = useRef();
  const audioRef = useRef();
  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));
    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      if(local){
        if(showVideo){
         videoTrack.disable();
        }else{
          videoTrack.enable();
        }
      }
      return () => {
        videoTrack.detach();
      };
    }

  }, [videoTracks, showVideo,local]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
    // eslint-disable-next-line
  }, [audioTracks]);
  let remoteAudioMute = false;
  //let remoteVideoMute = false;
  participant.audioTracks.forEach((value,key)=>{
    if(inactiveAudio.includes(key)){
      remoteAudioMute = true;
    }
  });
  // participant.videoTracks.forEach((value,key)=>{
  //   if(inactiveVideo.includes(key)){
  //     remoteVideoMute = true;
  //   }
  // });
 
  let participantIdentity = participant.identity.match(/\D/g).join('').replace('_','');

  return (
    <div style={length<2?{height:'560px', margin:'0%'}:{}} className={classes.participant}>
          {videoTracks.length>0 && !showVideo?
          <video 
            ref={videoRef} 
            autoPlay={true} 
            className={view==='grid' && length>0?'':'listViewMain'} 
            style={view==='grid'&& length>0?{height:'inherit',objectFit: 'cover' ,borderRadius:borderm, width:'99%' }:{}}
          />
          :
          <div className={classes.nameContainer} style={(length>0 || view!=='grid')?{}:{width:'200%'}}>
            <div className={classes.name}>{participant.identity[0].toUpperCase()}{participant.identity[1].toUpperCase()}</div>
          </div>
          }
          <audio ref={audioRef} autoPlay={true}  />
          {length>0 && videoTracks.length>0 && !showVideo &&
          <div className={classes.identity}>
           <div style={{ textTransform:'capitalize'}}>{participantIdentity}</div>
           <div style={{marginLeft: '42%',cursor:'pointer', marginRight:'10px'}}>
             {(mute || audioTracks.length === 0 || remoteAudioMute ) && 
             <img src='ui-assets/images/micro-off.svg'  alt="micro"  />
             }
           </div>
          </div>
          }
    </div>
  );
};

export default withStyles(styles)(Participant);
