import React, { useState, useEffect, isValidElement } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from "@material-ui/core";
import { withRouter } from 'react-router-dom';
import moment from "moment";
import { localStorageGet } from '../../ui-utils/commons';

const styles = ({
  cancel_button: {
    width: "91px",
    height: "40px",
    borderRadius: "12px",
    border: "solid 1px #089bab",
    boxShadow: "none"
  },
  cancel_text: {
    width: "59px",
    height: "24px",
    fontFamily: "'Open Sans'",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "normal",
    textAlign: "center",
    textTransform: "none",
    color: " #089bab"
  },
  deleteText: {
    width: "56px",
    height: "24px",
    fontFamily: "'Open Sans'",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
    textTransform: "none"
  },
  deleteButton: {
    width: "85px",
    borderRadius: "12px",
    background: "linear-gradient(#FF0000 30%, #FF0000)",
    height: "40px"
  }
});
function IdleMonitor({ classes, setAppData, history, errorChk }) {
  //Modal
  const [idleModal, setIdleModal] = useState(false);

  const timeout_value = 1000 * 60 * 45; // 45 minutes
  let idleEvent;

  /**
   * Start timer on initial render
   */
  useEffect(() => {
    // check time diff - session timeout
    const pageLeftTimeStamp = localStorageGet('pageLeftTimeStamp')
    if(pageLeftTimeStamp && pageLeftTimeStamp.length !== 0) {
      const time_diff = moment.duration(moment().diff(moment(pageLeftTimeStamp)))["_milliseconds"]
      if (window.localStorage.getItem('loggedIn') === 'true' && (time_diff > timeout_value) ) {
        window.localStorage.setItem('isSessionExpired', "true");
        window.localStorage.setItem('loggedIn', "false");
        setIdleModal(true)
      }
    }
    if (window.localStorage.getItem('loggedIn') === 'true' && !idleModal){
      localStorage.setItem('latestEventTimeStamp', moment());
      idleEvent = setTimeout(() => triggerPopup(), timeout_value);
    }
    else {
      idleEvent = false;
    }
  }, [])

  /**
   * Add any other events listeners here
   */
  const events = [
    'mousemove',
    'click',
    'keypress',
    'wheel'
  ];

  /**
   * @method sessionTimeout
   * This function is called with each event listener to set a timeout or clear a timeout.
   */
  const sessionTimeout = () => {
    const myStorage = window.localStorage;
    if (!!idleEvent) {
      // clear timer 
      clearTimeout(idleEvent);
      // initialize timer
      idleEvent = setTimeout(() => triggerPopup(), timeout_value);
      // set time stamp on event - to update session activity on multiple tabs
      if (window.localStorage.getItem('loggedIn') === 'true') {
        localStorage.setItem('latestEventTimeStamp', moment());
      }

      // get idle modal state from local storage -> for multiple tab usecase handling
      const idleModal_ls = localStorage.getItem('isSessionExpired');

      /**
         * check if user has already logged out
         * if already logged out -> "loggedIn" prop won't be present in local storage
         * -> redirect user to login page (for all open tabs with that session)
         */
      if (!myStorage?.loggedIn && !idleModal_ls) {
        history.push('/login')
      }

      // session expire for all tabs
      if (window.localStorage.getItem('loggedIn') === 'true' && idleModal_ls === "true") {
        const url_path = window.location.href.toString();

        // if we are in /reset password it will not intialize
        if (!url_path.includes("reset-password")) {
          setIdleModal(true)
        }
      }
    }
  };

  const triggerPopup = async () => {
    const url_path = window.location.href.toString();

    // if we are in /reset password it will not intialize
    if (!url_path.includes("reset-password")) {
      const mouseTime = localStorageGet('latestEventTimeStamp')
      const time_diff = moment.duration(moment().diff(moment(mouseTime)))["_milliseconds"]
      if (window.localStorage.getItem('loggedIn') === 'true' && (time_diff > timeout_value)) {
        await setAppData(
          "pages.patient.details.showNotification",
          false
        );
        await setAppData(
          "pages.patient.details.showChat",
          false
        );
        await setAppData("pages.patient.details.showSettings", false)
        window.localStorage.setItem('isSessionExpired', "true");
        setIdleModal(true);
      }
    }
  }
  /**
   * @method logOut
   * This function will destroy current user session.
   */
  function logOut() {
    setAppData('communication.video', false);
    setAppData('communication.token', null);
    setAppData('administratorSummary', {});
    setAppData('clinicianPortal', {});
    setAppData('communication', {});
    setAppData('dashboard', {});
    setAppData('mevents', []);
    setAppData('patientPortal', {});
    setAppData('patients', {});
    setAppData('savepatient', {});
    setAppData('userProfile', '');
    setAppData('errorChk', false);
    setAppData(`pages.patient.overview.dateRange`, { value: 'last_week', label: 'Last week' });
    setAppData("pages.patient.vitals.daysFilter", { value: 'last_week', label: 'Last week' })
    setAppData("pages.patient.symptoms.filters.daysFilter", { value: 'last_week', label: 'Last week' });
    setAppData("pages.patient.diet.filters.daysFilter", { value: 'last_week', label: 'Last week' });

    window.localStorage.setItem('isSessionExpired', "false");
    setIdleModal(false);
    history.push('/login');
    sessionStorage.clear()
    window.localStorage.clear();
  }

  useEffect(() => {
    if (errorChk) {
      window.localStorage.setItem('isSessionExpired', "true");
      setIdleModal(true);
    }
  }, [errorChk]);

  useEffect(() => {
    for (let e in events) {
      window.addEventListener(events[e], sessionTimeout);
    }

    return () => {
      for (let e in events) {
        window.removeEventListener(events[e], sessionTimeout);
      }
    }
    // eslint-disable-next-line
  }, []);

  /**
   * handle refresh of page 
   */
  useEffect(() => {
    window.addEventListener("beforeunload", onUnloadHandler);
    return () => {
      window.removeEventListener("beforeunload", onUnloadHandler);
    };
  }, []);
  const onUnloadHandler = () => {
    // set timeStamp when close tab
    window.localStorage.setItem('pageLeftTimeStamp', moment()) 
    // clear localstorage and re-direct to login if idleModal is "true" -> i.e, if session is already expired
    setIdleModal((prevState) => {
      if (prevState) {
        window.localStorage.clear();
      }
    })
  };

  /**
   * onFocus()
   */
  useEffect(() => {
    window.addEventListener("focus", onFocusHandler);
    return () => {
      window.removeEventListener("focus", onFocusHandler);
    };
  }, []);
  const onFocusHandler = async () => {
    const latest_mouse_time = localStorageGet('latestEventTimeStamp')
    const time_diff = moment.duration(moment().diff(moment(latest_mouse_time)))["_milliseconds"]
    if (window.localStorage.getItem('loggedIn') === 'true' && (time_diff > timeout_value)) {
      await setAppData(
        "pages.patient.details.showNotification",
        false
      );
      await setAppData(
        "pages.patient.details.showChat",
        false
      );
      await setAppData("pages.patient.details.showSettings", false)
      window.localStorage.setItem('isSessionExpired', "true");
      setIdleModal(true);
    }
  };

  return (

    <Dialog open={idleModal} >
      <DialogTitle>
        {`Session Expired`}
      </DialogTitle>
      <DialogContent>
        Your session has expired. Please login to renew session.
      </DialogContent>
      <DialogActions>
        <Button className={classes.deleteButton} onClick={() => logOut()}>
          <span className={classes.deleteText}>Login</span>
        </Button>
      </DialogActions>
    </Dialog>
  )

}

export default (withRouter(withStyles(styles)(IdleMonitor)));