import { get, isEmpty } from "lodash";
import { prepareFinalObject } from "../ui-redux/screen-configuration/actions";
import store from "../ui-redux/store";
import moment from 'moment';
import clevertap from "clevertap-web-sdk";

export const addQueryArg = (url, queries = []) => {
  const urlParts = url.split("?");
  const path = urlParts[0];
  let queryParts = urlParts.length > 1 ? urlParts[1].split("&") : [];
  queries.forEach((query) => {
    const key = query.key;
    const value = query.value;
    const newQuery = `${key}=${value}`;
    queryParts.push(newQuery);
  });
  const newUrl = path + "?" + queryParts.join("&");
  return newUrl;
};

export const getUrlParameterValue = (key) => {
  let params = new URL(document.location).searchParams;
  let value = params.get(key);
  return value;
};
export const versionBuild=() =>{
return{
  Version :"v1.4-Test",
  Build:"1116-Test"
}
}
export const age = (dateInString) => {
  const date = Number(dateInString);
  const birthdate = new Date(date);
  const cur = new Date();
  const diff = cur - birthdate; // This is the difference in milliseconds
  const value = Math.floor(diff / 31557600000);
  if (value > 0) {
    return value;
  } else return parseFloat((diff / 31557600000).toFixed(1));
};

export const formatCalendarDate = (notes = { auditDetails: {} }) => {
  if (!notes.auditDetails) return;
  const {
    auditDetails: { createdTime },
  } = notes;
  const date = new Date(Number(createdTime));
  return [date.getDate(), date.getMonth() + 1, date.getFullYear()];
};
export const heightCalculator = (value = 0, unit = 0) => {
  if (!value || !unit) return null;
  if (unit === "cm") return value / 100;
  else if (unit === "ft") return Math.round(value / 30.48);
  //this has to be change
  else if (unit === "m") return value / 100;
  else return value;
};
export const weightCalculator = (value = 0, unit = "") => {
  if (!value || !unit) return null;
  if (unit === "kg")
    // return Math.round(value / 2.24);
    return value;
  else if (unit === "lbs") return Math.round(value * 2.205);
  else return value;
};
export const bodyTemperatureCalculator = (value = 0, unit = "") => {
  if (!value || !unit) return null;
  if (unit === "c")
    // return (Number(value) - 32) * (9 / 5);
    return value;
  else if (unit === "f") return Math.round((value * 9) / 5 + 32);
  else return value;
};
export const bloodGlucoseCalculator = (value = 0, unit = 0) => {
  if (!value || !unit) return null;
  if (unit === "mmol/L")
    // return Math.round(value /18);
    return value;
  else if (unit === "mg") return Math.round(value * 18);
  else return value;
};
export const bmiCalculator = (mass, height) => {
  if (!Number(mass) || !Number(height)) return "---";
  return Math.round(mass / (height * height));
};
export const formatCalendarDateSimple = (createdTime) => {
  if (!createdTime) return null;
  const date = new Date(Number(createdTime));
  return [date.getDate(), date.getMonth() + 1, date.getFullYear()];
};
export const getTime = (createdAt) => {
  return createdAt && Array.isArray(new Date(createdAt).toString().split(" "))
    ? new Date(createdAt).toString().split(" ")[4].substring(0, 5)
    : "";
};
export const formatTherapyDuration = (duration) => {
  return `${Math.floor(duration / 12)} yr ${Math.floor(duration % 12)} m`;
};
export const formatAverageTime = (duration) => {
  if (!Number(duration)) return "---";
  if (Number(duration) < 3600) {
    var rminutes = Number(duration) / 60;
    return Math.round(rminutes) + "min";
  }
  const hours = Number(duration) / 3600;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  return (Math.floor(rhours) + "h " + Math.round(minutes) + "m").toLowerCase();
};
export const formatApproximateTherapy = (from, end, capd = false) => {
  var fromdate = moment(from);
  var todate = moment(end);
  if (fromdate.isValid() && todate.isValid() && end && !capd) {
    let duration = todate - fromdate;
    var minutes = Math.abs(moment.duration(duration).minutes());
    var hours = Math.abs(moment.duration(duration).hours());
    var days = Math.abs(moment.duration(duration).days());
    return (days ? days + "d " : "") + hours + "h " + minutes + "m ";
  } else if (capd && end) {
    let duration = todate - fromdate;
    return duration;
  }
  return "---";
};
export const formatDefalutDate = (time, formats) => {
  if (!time) return null;
  const date = moment(Number(time)).format(formats);
  return date;
};
export const defaultFormatTime = (time, formats) => {
  let tim_e = "";
  if (!time) return null;
  tim_e = moment(time).format(formats);
  return tim_e;
};
export const defaultFormatName = (firstname, lastname, type) => {
  let label = firstname + " " + lastname;
  return label;
};
const svgToDataUri = (svgStr) => {
  const encoded = encodeURIComponent(svgStr)
    .replace(/'/g, "%27")
    .replace(/"/g, "%22");

  const header = "data:image/svg+xml;charset=utf8,";
  const dataUrl = header + encoded;

  return dataUrl;
};
export const svgReplaceColor = (svgStr, color) => {
  return svgToDataUri(svgStr && svgStr.replace(/#xxxxxx/g, color));
};
export const flagNames = (flaggingSystem = []) => {
  let flagArray = [];
  flaggingSystem.length > 0 &&
    flaggingSystem.forEach((flagObj, index) => {
      flagArray.splice(index + 1, 0, flagObj.id);
    });
  return [...flagArray];
};
export const showDevelopment = () => {
  store.dispatch(
    prepareFinalObject("snackbar", {
      open: true,
      variant: "info",
      message: "Feature Under Development",
    })
  );
};

export const formatSelectLabel = (text = "") => {
  return (
    text.toLowerCase().substring(0, 1).toUpperCase() +
    text.toLowerCase().substring(1)
  );
};
export const backendHosts = () => {
  let backendHosts = {};
  const hostname = window && window.location && window.location.hostname;
  if (hostname.includes("staging") || hostname === "localhost" ) {
    backendHosts.AUTH_SERVICE =
      "https://awak-wk-auth-micro-service-staging.awakpd.com/";
    backendHosts.USER_SERVICE =
      "https://awak-wk-user-micro-service-staging.awakpd.com";
    backendHosts.PATIENT_SERVICE =
      "https://awak-wk-patient-micro-service-staging.awakpd.com";
    backendHosts.CLINICIAN_SERVICE =
      "https://awak-wk-clinician-micro-service-staging.awakpd.com";
    backendHosts.NOTES_SERVICE =
      "https://awak-wk-notes-micro-service-staging.awakpd.com";
    backendHosts.VITALS_SERVICE =
      "https://awak-wk-vital-logs-micro-service-staging.awakpd.com/";
    backendHosts.PATIENT_MEDICATION =
      "https://awak-wk-medication-micro-service-staging.awakpd.com";
    backendHosts.CLINIC_SERVICE =
      "https://awak-wk-clinic-micro-service-staging.awakpd.com";
    backendHosts.CONFIG_SERVICE =
      "https://awak-wk-config-micro-service-staging.awakpd.com";
    backendHosts.SYMPTOMS_SERVICE =
      "https://awak-wk-wk-backend-symptom-logs-staging.awakpd.com";
    backendHosts.APPOINTMENT_SERVICE =
      "https://awak-wk-appointment-micro-service-staging.awakpd.com";
    backendHosts.MOCK_API =
      "https://virtserver.swaggerhub.com";
    backendHosts.NOTIFICATION_SERVICE =
      "https://awak-wk-notification-micro-service-staging.awakpd.com";
    backendHosts.THERAPY_SERVICE =
      "https://awak-wk-theropy-logs-micro-service-staging.awakpd.com";
    backendHosts.DIET_SERVICE =
      "https://awak-wk-diet-logs-micro-service-staging.awakpd.com";
  } else if (hostname.includes("-qa")  ) {
    backendHosts.AUTH_SERVICE =
      "https://awak-wk-auth-micro-service-qa.awakpd.com/";
    backendHosts.USER_SERVICE =
      "https://awak-wk-user-micro-service-qa.awakpd.com";
    backendHosts.PATIENT_SERVICE =
      "https://awak-wk-patient-micro-service-qa.awakpd.com";
    backendHosts.CLINICIAN_SERVICE =
      "https://awak-wk-clinician-micro-service-qa.awakpd.com";
    backendHosts.NOTES_SERVICE =
      "https://awak-wk-notes-micro-service-qa.awakpd.com";
    backendHosts.VITALS_SERVICE =
      "https://awak-wk-vital-logs-micro-service-qa.awakpd.com/";
    backendHosts.PATIENT_MEDICATION =
      "https://awak-wk-medication-micro-service-qa.awakpd.com";
    backendHosts.CLINIC_SERVICE =
      "https://awak-wk-clinic-micro-service-qa.awakpd.com";
    backendHosts.CONFIG_SERVICE =
      "https://awak-wk-config-micro-service-qa.awakpd.com";
    backendHosts.SYMPTOMS_SERVICE =
      "https://awak-wk-wk-backend-symptom-logs-qa.awakpd.com";
    backendHosts.APPOINTMENT_SERVICE =
      "https://awak-wk-appointment-micro-service-qa.awakpd.com";
    backendHosts.MOCK_API =
      "https://virtserver.swaggerhub.com";
    backendHosts.NOTIFICATION_SERVICE =
      "https://awak-wk-notification-micro-service-qa.awakpd.com";
    backendHosts.THERAPY_SERVICE =
      "https://awak-wk-theropy-logs-micro-service-qa.awakpd.com";
    backendHosts.DIET_SERVICE =
      "https://awak-wk-diet-logs-micro-service-qa.awakpd.com";
  } else if (hostname.includes("demo")) {
    backendHosts.AUTH_SERVICE =
      "https://awak-wk-auth-micro-service-demo.awakpd.com/";
    backendHosts.USER_SERVICE =
      "https://awak-wk-user-micro-service-demo.awakpd.com";
    backendHosts.PATIENT_SERVICE =
      "https://awak-wk-patient-micro-service-demo.awakpd.com";
    backendHosts.CLINICIAN_SERVICE =
      "https://awak-wk-clinician-micro-service-demo.awakpd.com";
    backendHosts.NOTES_SERVICE =
      "https://awak-wk-notes-micro-service-demo.awakpd.com";
    backendHosts.VITALS_SERVICE =
      "https://awak-wk-vital-logs-micro-service-demo.awakpd.com/";
    backendHosts.PATIENT_MEDICATION =
      "https://awak-wk-medication-micro-service-demo.awakpd.com";
    backendHosts.CLINIC_SERVICE =
      "https://awak-wk-clinic-micro-service-demo.awakpd.com";
    backendHosts.CONFIG_SERVICE =
      "https://awak-wk-config-micro-service-demo.awakpd.com";
    backendHosts.SYMPTOMS_SERVICE =
      "https://awak-wk-wk-backend-symptom-logs-demo.awakpd.com";
    backendHosts.APPOINTMENT_SERVICE =
      "https://awak-wk-appointment-micro-service-demo.awakpd.com";
    backendHosts.MOCK_API =
      "https://virtserver.swaggerhub.com";
    backendHosts.NOTIFICATION_SERVICE =
      "https://awak-wk-notification-micro-service-demo.awakpd.com";
    backendHosts.THERAPY_SERVICE =
      "https://awak-wk-theropy-logs-micro-service-demo.awakpd.com";
    backendHosts.DIET_SERVICE =
      "https://awak-wk-diet-logs-micro-service-demo.awakpd.com";
  } else if (hostname.includes("dev")) {
    backendHosts.AUTH_SERVICE =
      "https://awak-wk-auth-micro-service-dev.awakpd.com/";
    backendHosts.USER_SERVICE =
      "https://awak-wk-user-micro-service-dev.awakpd.com";
    backendHosts.PATIENT_SERVICE =
      "https://awak-wk-patient-micro-service-dev.awakpd.com";
    backendHosts.CLINICIAN_SERVICE =
      "https://awak-wk-clinician-micro-service-dev.awakpd.com";
    backendHosts.NOTES_SERVICE =
      "https://awak-wk-notes-micro-service-dev.awakpd.com";
    backendHosts.VITALS_SERVICE =
      "https://awak-wk-vital-logs-micro-service-dev.awakpd.com/";
    backendHosts.PATIENT_MEDICATION =
      "https://awak-wk-medication-micro-service-dev.awakpd.com";
    backendHosts.CLINIC_SERVICE =
      "https://awak-wk-clinic-micro-service-dev.awakpd.com";
    backendHosts.CONFIG_SERVICE =
      "https://awak-wk-config-micro-service-dev.awakpd.com";
    backendHosts.SYMPTOMS_SERVICE =
      "https://awak-wk-wk-backend-symptom-logs-dev.awakpd.com";
    backendHosts.APPOINTMENT_SERVICE =
      "https://awak-wk-appointment-micro-service-dev.awakpd.com";
    backendHosts.MOCK_API =
      "https://virtserver.swaggerhub.com";
    backendHosts.NOTIFICATION_SERVICE =
      "https://awak-wk-notification-micro-service-dev.awakpd.com";
    backendHosts.THERAPY_SERVICE =
      "https://awak-wk-theropy-logs-micro-service-dev.awakpd.com";
    backendHosts.DIET_SERVICE =
      "https://awak-wk-diet-logs-micro-service-dev.awakpd.com";
  } else {
    backendHosts.AUTH_SERVICE = "https://auth-micro-service.wekare.sg/";
    backendHosts.USER_SERVICE = "https://user-micro-service.wekare.sg";
    backendHosts.PATIENT_SERVICE =
      "https://patient-micro-service.wekare.sg";
    backendHosts.CLINICIAN_SERVICE =
      "https://clinician-micro-service.wekare.sg";
    backendHosts.NOTES_SERVICE =
      "https://notes-micro-service.wekare.sg";
    backendHosts.VITALS_SERVICE =
      "https://vital-logs-micro-service.wekare.sg";
    backendHosts.PATIENT_MEDICATION =
      "https://medication-micro-service.wekare.sg";
    backendHosts.CLINIC_SERVICE =
      "https://clinic-micro-service.wekare.sg";
    backendHosts.CONFIG_SERVICE =
      "https://config-micro-service.wekare.sg";
    backendHosts.SYMPTOMS_SERVICE =
      "https://symptoms-logs-micro-service.wekare.sg";
    backendHosts.APPOINTMENT_SERVICE =
      "https://appointment-micro-service.wekare.sg";
    backendHosts.MOCK_API =
      "https://virtserver.swaggerhub.com";
    backendHosts.NOTIFICATION_SERVICE =
      "https://notification-micro-service.wekare.sg";
    backendHosts.THERAPY_SERVICE =
      "https://therapy-logs-micro-service.wekare.sg";
    backendHosts.DIET_SERVICE =
      "https://diet-logs-micro-service.wekare.sg";
  }
  // backendHosts.AUTH_SERVICE = process.env.REACT_APP_AUTH_SERVICE;
  // backendHosts.USER_SERVICE = process.env.REACT_APP_USER_SERVICE;
  // backendHosts.PATIENT_SERVICE = process.env.REACT_APP_PATIENT_SERVICE;
  // backendHosts.CLINICIAN_SERVICE = process.env.REACT_APP_CLINICIAN_SERVICE;
  // backendHosts.NOTES_SERVICE = process.env.REACT_APP_NOTES_SERVICE;
  // backendHosts.VITALS_SERVICE = process.env.REACT_APP_VITALS_SERVICE;
  // backendHosts.PATIENT_MEDICATION = process.env.REACT_APP_PATIENT_MEDICATION;
  // backendHosts.CLINIC_SERVICE = process.env.REACT_APP_CLINIC_SERVICE;
  // backendHosts.CONFIG_SERVICE = process.env.REACT_APP_CONFIG_SERVICE;
  // backendHosts.SYMPTOMS_SERVICE = process.env.REACT_APP_SYMPTOMS_SERVICE;
  // backendHosts.APPOINTMENT_SERVICE = process.env.REACT_APP_APPOINTMENT_SERVICE;
  // backendHosts.MOCK_API = process.env.REACT_APP_MOCK_API;
  // backendHosts.NOTIFICATION_SERVICE =process.env.REACT_APP_NOTIFICATION_SERVICE;
  // backendHosts.THERAPY_SERVICE = process.env.REACT_APP_THERAPY_SERVICE;
  // backendHosts.DIET_SERVICE = process.env.REACT_APP_DIET_SERVICE;
  return backendHosts;
};

export const mapDispatchToProps = (dispatch) => {
  return {
    setAppData: (jsonPath, data) => {
      dispatch(prepareFinalObject(jsonPath, data));
    },
  };
};
export const getDoctorRoles = (datas = []) => {
  let roledata = [];
  datas.length > 0 &&
    datas.forEach((clinician, key) => {
      if (clinician.userInfo) {
        const { firstname = "", lastname = "" } = clinician.userInfo || {};
        clinician.userInfo.roles.forEach((role, key) => {
          if (role && role.uuid) {
            roledata.push({
              label: firstname + " " + lastname,
              value: clinician.uuid,
            });
          }
        });
      }
    });
  return roledata;
};
export function capitalize(s) {
  s = s.toLowerCase();
  return s[0].toUpperCase() + s.slice(1);
}

export const getadminTableData = (datas = []) => {
  let tableData = [];
  let roledata = [];
  let roleObj = [];
  datas.length > 0 &&
    datas.forEach((clinician, key) => {
      if (clinician.userInfo) {
        const {
          firstname = "",
          lastname = "",
          roles = [],
          email = "",
          phone = "",
        } = clinician.userInfo || {};

        let arr1 = roles.filter((item) => item.uuid === "123456789018");

        let arr2 = [];
        if (arr1.length === 0) {
          arr2 = roles.filter((item) => item.name !== "123456789018");
          arr2.forEach((role, key) => {
            switch (role.uuid) {
              case `${role.uuid}`:
                if (
                  role.uuid !== "123456789018" ||
                  role.uuid !== "123456789012"
                ) {
                  if (!roleObj[clinician.uuid]) {
                    roledata.push({
                      label: firstname + " " + lastname,
                      value: clinician.uuid,
                    });
                    roleObj[clinician.uuid] = {
                      ...roleObj[clinician.uuid],
                      ...clinician,
                    };
                  }
                }
                break;
              default:
            }
          });
        } else {
          let capsFirstName = firstname ? capitalize(firstname) : "";
          let capsLastName = lastname ? capitalize(lastname) : "";
          let role = roles
            .filter((item) => item.uuid !== "123456789018")
            .filter((key) => key.uuid !== "123456789013");
          tableData[tableData.length] = [
            clinician.id,
            capsFirstName,
            capsLastName,
            role.length ? capitalize(role[0].name.toLowerCase()) : "",
            email,
            `+${phone}`,
          ];
          //});
        }
      }
    });
  return {
    tableData: [...tableData],
    roleData: roledata,
    clinicans_array: roleObj,
  };
};
export const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true;
  } else {
    return false;
  }
};
export const getAssignedPhysicians = (datas = [], physicians) => {
  let tableData = [];
  datas.length > 0 &&
    datas.forEach((clinician, key) => {
      if (clinician.userInfo) {
        const {
          uuid = "",
          firstname = "",
          lastname = "",
          roles = [],
        } = clinician.userInfo || {};
        const { name } = physicians || {};
        if (!isEmpty(physicians)) {
          if (uuid !== name.value) {
            tableData.push({
              label: firstname + " " + lastname,
              value: clinician.uuid,
            });
          }
        } else {
          tableData.push({
            label: firstname + " " + lastname,
            value: clinician.uuid,
          });
        }
        // }
      }
    });
  return [...tableData];
};
export const getCreatedByName = (id, clinicians = []) => {
  if (!clinicians) return id;
  const clinicianObj =
    clinicians.length > 0 && clinicians.find((cli) => cli.uuid === id);
  const firstName = get(clinicianObj, "userInfo.firstname", null);
  const lastName = get(clinicianObj, "userInfo.lastname", null);
  return firstName + " " + lastName;
};

export const checkCamera = async () => {
  let permission = true;
  return permission;
};

export const wrapRequestBody = (requestBody, action) => {
  const authToken = localStorageGet("userInfo", "OAuthDetails.authToken");
  const requestId = localStorageGet("userInfo", "User.uuid");
  const clinicUUID = localStorageGet("userInfo", "User.clinic_uuid");
  let RequestInfo = {
    apiId: "AWAK_WEB",
    ver: "V1",
    ts: `${getDateInEpoch()}`,
    action: action,
    did: "1",
    key: "",
    msgId: "1605595169022|en_IN",
    requesterId: requestId,
    authToken: authToken,
    clinicUUID,
  };
  if (action !== "_search") {
    return Object.assign(
      {},
      {
        RequestInfo,
      },
      requestBody
    );
  } else {
    return {
      RequestInfo,
      ...requestBody,
    };
  }
};
export const localStorageGet = (key, path) => {
  let value = null;
  if (path) {
    const data = JSON.parse(window.localStorage.getItem(key)) || null;
    value = get(data, path);
  } else {
    value = window.localStorage.getItem(key) || null;
  }

  return value;
};
export const getDateInEpoch = () => {
  return new Date().toUTCString();
};
export const compareTime = (to, from) => {
  let flag = true;
  let a = moment([to.split(":")[0], to.split(":")[1], "00"], "HH:mm:ss");
  let b = moment([from.split(":")[0], from.split(":")[1], "00"], "HH:mm:ss");
  let difftm = moment.duration(a.diff(b));
  let mins = difftm.minutes();
  let hours = difftm.hours();
  if (hours < 0) {
    flag = false;
  } else if (hours <= 0 && mins <= 0) {
    flag = false;
  }

  return flag;
};
export const getPercentage = (data, total) => {
  if (Number(data) !== 0) {
    let data_label = Number(data) / Number(total);
    const result = data_label * 100;
    return Math.round(result);
  } else {
    return 0;
  }
};

export const isAdminClinician = (pArr) => {
  let ids = pArr.filter((key) => !key.role.value).map((item) => item.roleId);
  if (!isEmpty(ids)) {
    return false;
  }
  return true;
};

export const checkAdmin = () => {
  let pArr = localStorageGet("userInfo", "User.additionalInfo.permissions");
  let ids = pArr.filter((key) => !key.role.value).map((item) => item.roleId);
  if (!isEmpty(ids)) {
    return false;
  }
  return true;
};

export const rearrangeColumns = (columns) => {
  let rearrangeColumns = {};

  Object.entries(columns).forEach((column) => {
    if (column[1] == true) {
      rearrangeColumns[column[0]] = column[1];
    }
  });

  Object.entries(columns).forEach((column) => {
    if (column[1] == false) {
      rearrangeColumns[column[0]] = column[1];
    }
  });

  return rearrangeColumns;
};

export const invertColumns = (columns, fixedrow, currPage, pageSize) => {
  let invertedColumn = {};
  let tempCnt = 0;
  let tempPage = 0;

  Object.entries(columns).forEach((column) => {
    if (!fixedrow.includes(column[0])) {
      if (tempPage == currPage) {
        invertedColumn[column[0]] = true;
      } else {
        invertedColumn[column[0]] = false;
      }
      tempCnt++;
      if (tempCnt % (pageSize - fixedrow.length) == 0) {
        tempPage++;
      }
    } else {
      invertedColumn[column[0]] = true;
    }
  });
  return invertedColumn;
};
export const checkArray = (array) => Array.isArray(array) && array.length;

export const ValidateEmail = (mail) => {
  if (mail == "") {
    store.dispatch(
      prepareFinalObject("snackbar", {
        open: true,
        variant: "error",
        message: "Please Enter an Email",
      })
    );
    return false;
  } else {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    } else {
      store.dispatch(
        prepareFinalObject("snackbar", {
          open: true,
          variant: "error",
          message: "You have entered an invalid email address!",
        })
      );
      return false;
    }
  }
};

export const dietMap = {
  ca: "Calcium",
  chocdf: "Carbs",
  chole: "Cholesterol",
  fams: "Monounsaturated",
  fapu: "Polyunsaturated",
  fasat: "Saturated",
  fat: "Fat",
  fatrn: "Trans",
  fe: "Iron",
  fibtg: "Fiber",
  foldfe: "Folate",
  k: "Potassium",
  mg: "Magnesium",
  na: "Sodium",
  enerc_kcal: "Energy	kcal",
  nia: "Niacin (B3)",
  p: "Phosphorus",
  procnt: "Protein",
  ribf: "Riboflavin (B2)",
  sugar: "Sugars",
  thia: "Thiamin (B1)",
  tocpha: "Vitamin E",
  vita_rae: "Vitamin A",
  vitb12: "Vitamin B12",
  vitb6a: "Vitamin B6",
  vitc: "Vitamin C",
  vitd: "Vitamin D",
  vitk1: "Vitamin K",
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// exit site graph colors
export const exitSiteGraphColors = {
  CLEAN_AND_DRY : "#B5F7B8",
  SWOLLEN : "#F3EB91",
  PUS_PRESENT : "#F7B5B5",
  REDNESS : "#F9CA9F",
  BLOOD_STAINED_DRESSING : "#BA3B47",
  BLOOD_OOZING_OUT : "#FF6821",
}
// exit site graph labels for legends
export const exitSiteGraphLabels = {
  CLEAN_AND_DRY : "Clean And Dry",
  SWOLLEN : "Swollen",
  PUS_PRESENT : "Pus Present",
  REDNESS : "Redness",
  BLOOD_STAINED_DRESSING : "Blood Stained",
  BLOOD_OOZING_OUT : "Blood Oozing Out",
}

// drain clarity graph colors
export const drainClarityGraphColors = {
  CLEAR : "#b5f7b8",
  SLIGHTLY_CLOUDY: "#a3b0f5",
  VERY_CLOUDY: "#dd9ff9"
}
// drain clarity graph labels for legends
export const drainClarityGraphLabels = {
  CLEAR: "Clear",
  SLIGHTLY_CLOUDY: "Slightly Cloudy",
  VERY_CLOUDY: "Very Cloudy"
}

// foreign object graph colors
export const foreignObjectGraphColors = {
  NONE: "#d3f99f",
  FIBRIN: "#f5d9a3",
  OTHERS: "#f7b5b5"
}
// foreign Object graph labels for legends
export const foreignObjectGraphLabels = {
  NONE: "None",
  FIBRIN: "Fibrin",
  OTHERS: "Others"
}

// drain color graph colors
export const drainColorGraphColors = {
  LIGHT_YELLOW: "#F7F2B5",
  DARK_YELLOW: "#F3EB91",
  LIGHT_ORANGE: "#F9CA9F",
  LIGHT_RED: "#F5AEA3"
}
// drain color grpah labels for legends
export const drainColorGraphLabels = {
  LIGHT_YELLOW: "Light Yellow",
  DARK_YELLOW: "Dark Yellow",
  LIGHT_ORANGE: "Light Orange",
  LIGHT_RED: "Light Red"
}

export let snackbarObj = {};
snackbarObj.open = true;
snackbarObj.variant = "error";

export const clevertapInit = () => {
  if(!clevertap.getCleverTapID() || !clevertap.getAccountID()){
    // log level for clevertap debug logs
    const LOG_LEVEL = {
      LEVEL_0 : 0, // Disable all logs
      LEVEL_1 : 1, // display only errors
      LEVEL_2 : 2, // display errors and info
      LEVEL_3 : 3, // display all logs
    }
  
    // extract url path
    const url_path = window.location.href.toString();
  
    // point clevertap to localhost/staging/dev/qa or production according to the extracted URL
    if(url_path.includes("dev") || url_path.includes("qa") || url_path.includes("staging") || url_path.includes("localhost")) {
      // clevertap.init("6ZR-9W4-4Z6Z", "eu1");
      clevertap.init("6ZR-9W4-4Z6Z", "eu1");
    }
    else {
      clevertap.init("WWW-R78-9R6Z", "eu1");
      // clevertap.init("WWW-R78-9R6Z", "eu1" );
    }

    clevertap.privacy.push({optOut: false}) // Set the flag to true, if the user of the device opts out of sharing their data
    clevertap.privacy.push({useIP: false})  // Set the flag to true, if the user agrees to share their IP data
  
    // set log level
    clevertap.setLogLevel(LOG_LEVEL.LEVEL_3)

  }
}

/**
 * converts hours and mins to single value representation
 * eg: 9hrs 30mins -> 9.5
 * @param {*} hours 
 * @param {*} minutes 
 * @returns formatted value
 */
export const formatDurationValue = (hours, minutes) => {
  let durationFormattedValue = hours + (minutes / 60)
  durationFormattedValue = durationFormattedValue.toFixed(2);
  return durationFormattedValue
}
export const getHoursFromDuration = (durationValue) => {
  return Math.floor(Number(durationValue))
}
export const getMinutesFromDuration = (durationValue) => {
  return Math.round((durationValue % 1).toFixed(2) * 60, 0)
}

export const pushClevertapEvent = (event_name) => {
  // clevertap init
  clevertapInit();
  // trigger event
  if(clevertap.getCleverTapID() && clevertap.getAccountID()){
    clevertap.event.push(event_name)
    console.log(`------ Clevertap-event - ${event_name} - triggered ------`)
  }
  else {
    console.log(`------ Error in ${event_name} ------`)
  }
}

export const pushClevertapEventPayload = (event_name, payload) => {
  // clevertap init
  clevertapInit();
  // trigger event with payload
  if(clevertap.getCleverTapID() && clevertap.getAccountID()){
    clevertap.event.push(event_name, payload)
    console.log(`------ Clevertap-event - ${event_name} - triggered ------`)
  }
  else {
    console.log(`------ Error in ${event_name} ------`)
  }
}

export const FEATURES_LIST = {
  DIET: "Diet",
  THERAPY: "Therapy",
  VITALS: "Vital",
  SYMPTOMS: "Symptoms",
  MEDICATION: "Medication",
  NOTES: "Notes",
  APPOINTMENT: "Appointment"
}

export const checkIsFeatureEnabled = (features_list, feature_name) => {
  const feature_details = features_list.filter((feature) => feature.featureName === feature_name)
  const isFeatureEnabled = feature_details[0]?.isEnabled
  return isFeatureEnabled
}

export const toggleFeaturesLocally = (featureList) => {
  // disable it to take it from response directly
  if(false) {
    const toggleObj = {
      "Diet": false,
      "Therapy": true,
      "Vital": true,
      "Symptoms": false,
      "Medication": false,
      "Notes": false,
      "Appointment": false,
    }
    return featureList.map((feature) => {
      feature.isEnabled = toggleObj[feature?.featureName]
      return feature
    })
  } else {
    return featureList
  }
}