import React, { useState, useEffect, useRef } from "react";
import {withStyles} from "@material-ui/core";
const styles = theme => ({
  participant:{
   marginBottom:'5%',
   height:'194px'
  },
  identity:{
    display:'flex',
    justifyContent:'flex-end',
    fontFamily:'PoppinsBold',
    fontSize:'16px',
    lineHeight:'24px',
    letterSpacing:0,
    color:'white',
    position:'relative',
    bottom:'37px'
  }
});
const RemoteParticipant = ({ participant,classes, borderm, view, mute=false}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  //const [mute, muteAudio] = useState(false);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      if(mute){
        audioTrack.disable()
      }else{
        audioTrack.attach(audioRef.current);
      }
      return () => {
        audioTrack.detach();
      };
    }
    // eslint-disable-next-line
  }, [audioTracks]);
  let participantIdentity = participant.identity.match(/\D/g).join('').replace('_','');
  return (
    <div className={classes.participant}>
          <video ref={videoRef} autoPlay={true} style={{height:'inherit',objectFit: 'cover' ,borderRadius:borderm, width:'99%' }}/>
          <audio ref={audioRef} autoPlay={true}  muted={mute}/>
          <div className={classes.identity}>
           <div style={{margin:'0 auto', textTransform:'capitalize'}}>{participantIdentity}</div>
           {/* <div onClick={()=>{muteAudio(!mute)}}>
             <img src='ui-assets/images/micro-off.svg'  alt="micro" style={{marginLeft: '75px', cursor:'pointer', marginRight:'10px'}} />
           </div> */}
          </div>
            
          
    </div>
  );
};

export default withStyles(styles)(RemoteParticipant);
