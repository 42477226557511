import React from "react";
import { withRouter } from "react-router-dom";
//import { useClearCache } from 'react-clear-cache';
import Snackbar from "./ui-containers/SnackBar";
import { localStorageGet, mapDispatchToProps, pushClevertapEvent } from "./ui-utils/commons";
import MainRoutes from "./ui-routes/MainRoutes";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import { Hidden } from "@material-ui/core";
import VideoCall from "./ui-molecules/Video";
import Idlecontainer from "./ui-molecules/Idlecontainer";
import "./App.css";
import { isEmpty } from "lodash";



class App extends React.Component {
  state = {
    reRender: false
  }

  // check if url is for login page
  checkIfLoginURL = () => {
    const url = window.location.href;
    const home_index = url.indexOf('/#/')
    const string_after_slash = url.slice(home_index + 3, url.length-1) // '+3' to start after "/#/"
    const has_slash = string_after_slash.includes('/')
    return ( (!has_slash && string_after_slash.length === 0) || url.includes('login') ) ? true : false
  }

  componentDidMount = async () => {
    const { i18n, selectedLanguage, setAppData } = this.props;
    i18n.changeLanguage(selectedLanguage);
    const mdmsDataRes = localStorageGet("mdmsData");
    if (mdmsDataRes) {
      setAppData("mdmsData", JSON.parse(mdmsDataRes));
    }
    // check if session storage is empty
    // if empty then set userInfo in session storage
    const userDataFromLS = localStorageGet('userInfo');
    const userInfoSession = sessionStorage?.userInfoSession;
    if(userDataFromLS && !userInfoSession){ 
      sessionStorage.setItem('userInfoSession', userDataFromLS)
    }
    
    // compare "uuid" -> in session and local-Storage
    window.addEventListener('focus', () => {
      if(sessionStorage?.userInfoSession){
        const sessionStorage_userInfo = JSON.parse(sessionStorage?.userInfoSession)
        const userInfo_from_ls = JSON.parse(localStorageGet('userInfo'))
        if(sessionStorage_userInfo?.User?.uuid !== userInfo_from_ls?.User?.uuid && !this.checkIfLoginURL()){
          window.location.reload()
        }
      }
    }); 
  }

  endVideo = () => {
    const { setAppData } = this.props;
    
    // ENDING_A_CALL event
    pushClevertapEvent("ENDING_A_CALL")

    setAppData('communication.video', false);
    setAppData('communication.token', null);
    this.setState({ reRender: true })
  }
  render() {
    const { spinner, communication = {}, setAppData, errorChk } = this.props;
    const { endVideo } = this;
    const { video, name, token, appointmentClinicians = [], roomId, vidChannel, pChatid ='' } = communication;
    console.log(window.location.href.includes('mobile-'),"check1")
    console.log(window.location.href.includes('reset-password'),"check2")
    return (
      <div>
        <Hidden only={["md", "lg", "xl"]}>
          {window.location.href && window.location.href.includes('mobile-') || window.location.href.includes('reset-password')? 
          <MainRoutes />
          :
          <div className='mobile' />
          }
        </Hidden>
        <Hidden only={["xs", "sm"]}>
          <LoadingOverlay active={spinner}>
            <div>
              <MainRoutes />
              {((localStorageGet('loggedIn') && isEmpty(token) ) || errorChk) &&
              <Idlecontainer setAppData={setAppData}  errorChk={errorChk}/>
              }
              <Snackbar />
              {video &&
                <VideoCall
                  setAppData={setAppData}
                  endVideo={endVideo}
                  name={name}
                  token={token}
                  appointmentClinicians={appointmentClinicians}
                  roomId={roomId}
                  channel={vidChannel}
                  pChatid={pChatid}
                  communication={communication}
                />
              }
            </div>
          </LoadingOverlay>
        </Hidden>
      </div>
    );
  }
}
// const App= ({
//   spinner, 
//   communication = {}, 
//   setAppData,
//   selectedLanguage,
//   i18n
// }) => {
//   const { isLatestVersion, emptyCacheStorage } = useClearCache();
//   // eslint-disable-next-line
//   const [reRender, setRerender] = useState(false);
//   const { video, name, token, appointmentClinicians = [], roomId, vidChannel, pChatid ='' } = communication;
//   useEffect(()=>{
//     i18n.changeLanguage(selectedLanguage);
//     const mdmsDataRes = localStorageGet("mdmsData");
//     if (mdmsDataRes) {
//       setAppData("mdmsData", JSON.parse(mdmsDataRes));
//     }
//     // eslint-disable-next-line
//   },[reRender]);

//   function endVideo(){
//     setAppData('communication.video', false);
//     setAppData('communication.token', null);
//     setRerender(!reRender);
//   }
//   return (
//     <div>
//       {!isLatestVersion ?
//        (
//         <p>
//           {/* eslint-disable-next-line */}
//           <a
//             href="/login"
//             onClick={e => {
//               e.preventDefault();
//               emptyCacheStorage();
//               window.location.reload()
//             }}
//           >
//             Update version
//           </a>
//         </p>
//       ):
//       <div>
//         <Hidden only={["md", "lg", "xl"]}>
//           <div className='mobile' />
//         </Hidden>
//         <Hidden only={["xs", "sm"]}>
//           <LoadingOverlay active={spinner}>
//             <div>
//               <MainRoutes />
//               <Snackbar />
//               {video &&
//                 <VideoCall
//                   setAppData={setAppData}
//                   endVideo={endVideo}
//                   name={name}
//                   token={token}
//                   appointmentClinicians={appointmentClinicians}
//                   roomId={roomId}
//                   channel={vidChannel}
//                   pChatid={pChatid}
//                   communication={communication}
//                 />
//               }
//             </div>
//           </LoadingOverlay>
//         </Hidden>
//       </div>
//       }
//     </div>
//   );
// };
const mapStateToProps = ({ screenConfiguration }) => {
  const { preparedFinalObject = {} } = screenConfiguration;
  const { spinner = false, selectedLanguage = "en", communication, errorChk } = preparedFinalObject;
  return { spinner, selectedLanguage, communication, errorChk };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(App)));
