import React, { useState, useEffect } from "react";
import Video from "twilio-video";
import { Grid } from "@material-ui/core";
import Participant from "./participant";
import RemoteParticipant from "./remote";

const borderObj=['0 32px 0 0','0 0 0 32px','0 0 32px 0']
const Room = ({ roomName, token, view, mute, showVideo,roomId }) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [inactiveAudio, setAudio] = useState([]);
 // const [inactiveVideo, setVideo] = useState([]);
 
  if(room){
    function handleTrackDisabled(track) {
      track.on('disabled', () => {
       if(track.kind === 'audio'){
        setAudio([...inactiveAudio,track.sid])
       }
      //  if(track.kind === 'video'){
      //   setVideo([...inactiveVideo,track.sid])
      //  }
      });
    }
    function handleTrackEnabled(track) {
      track.on('enabled', () => {
        if(track.kind === 'audio'){
          const index = inactiveAudio.indexOf(track.sid);
          let newAudio = JSON.parse(JSON.stringify(inactiveAudio));
          if (index > -1) {
            newAudio.splice(index, 1);
          }
          setAudio(newAudio);
        }
        // if(track.kind === 'video'){
        //   const vindex = inactiveVideo.indexOf(track.sid);
        //   let newVideo = JSON.parse(JSON.stringify(inactiveVideo));
        //   if (vindex > -1) {
        //     newVideo.splice(vindex, 1);
        //   }
        //   setVideo(newVideo);
        // }
      });
    }
    room.participants.forEach(participant => {
      participant.tracks.forEach(publication => {
        if (publication.isSubscribed) {
          handleTrackDisabled(publication.track);
        }
        publication.on('subscribed', handleTrackDisabled);
      });
      participant.tracks.forEach(publication => {
        if (publication.isSubscribed) {
          handleTrackEnabled(publication.track);
        }
        publication.on('subscribed', handleTrackEnabled);
      });
    });
  }

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    Video.connect(token, {
      name: roomName,
    }).then((room) => {
      setRoom(room);
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);
    });

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token,roomId]);
  const remoteParticipants = participants.map((participant, index) => {

    return index<3 && 
    (view==='grid' ?
    <Grid item xs={index===1? 12: 6 }>
      <Participant  
        length={participants.length} 
        inactiveAudio={inactiveAudio }
       // inactiveVideo={inactiveVideo }
        key={participant.sid} 
        participant={participant}  
        borderm={borderObj[index]} 
        view={view} 
      />
    </Grid>
    :
    <>
      <RemoteParticipant 
        length={participants.length} 
        key={participant.sid} 
        participant={participant}
        inactiveAudio={inactiveAudio }  
      //  inactiveVideo={inactiveVideo }
        borderm={borderObj[index]} 
        view={view} 
      />
    </>
    )
  });

 useEffect(()=>{
  if(room){
    if(mute){
      room.localParticipant.audioTracks.forEach(publication => {
        publication.track.disable();
      });
    }else{
      room.localParticipant.audioTracks.forEach(publication => {
        publication.track.enable();
      });
    }
  }
// eslint-disable-next-line
 },[mute]);

  return (
    // <div className="room">
      <Grid container style={participants.length<2?{height:'560px', overflow:'hidden'}:{}}>
        <Grid item xs={6}>
          {room ? (
            <Participant
              key={room.localParticipant.sid}
              participant={room.localParticipant}
              length={participants.length}
              inactiveAudio={inactiveAudio }
            //  inactiveVideo={inactiveVideo}
              borderm='32px 0 0 0'
              view={view}
              mute={mute}
              showVideo={showVideo}
              local={true}
            />
          ) : (
            ""
          )}
        </Grid>
        {view==='list' ?
        <ul className='remoteParticipant'>
        {remoteParticipants}
        </ul>
        :
        remoteParticipants
        }
      </Grid>
   
    // </div>
  );
};

export default Room;
