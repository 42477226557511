import * as screenActionTypes from "./actionTypes";
import { prepareFinalObject } from "./utils";

const intialState = {
  preparedFinalObject: {
    snackbar: {
      open: false,
      variant: "success",
      message: "",
    },
    spinner: false,
    feedback: {
      hover: 0,
      value: 0,
      name: "",
      description: "",
    },
    savepatient: {
      profile: {},
    },
    saveClinician: {
      firstName: "",
      lastName: "",
      jobTitle: "",
      email: "",
      phone: "",
      status: "",
      gender: "",
    },
    pages: {
      patient: {
        home: {
          editColumnModal: false,
          tab: 0,
          filters: {
            patients: "all",
            priority: "all",
            types: "all",
            disease: "all",
          },
          searchFilter: "",
          columns: {
            patientID: true,
            name: true,
            gender: true,
            age: true,
            ckd: true,
            morbities: true,
            last_therapy: true,
            edit: true,
            ultrafiltration: false,
            blood_pressure: false,
            symptoms: false,
            glucose: false,
            weight: false,
            drain: false,
            exit: false,
          },
          currColumnPage: 0,
          visibleColumnCnt: 8,
          fixedColumnCnt: 3
        },
        details: {
          tab: 0,
          showChat: false,
          showNotification: false,
        },
        therapy: {
          tab: 1,
          historyModal: false,
          addPrescriptionModal: false,
          editPrescriptionModal: [],
          deletePrescriptionModal: [],
          collapsible: ["collapsible-1"],
          logDetailsModal: false,
          editColumnModal: false,
          prescriptionDropDown: false,
          therapyOpen: false,
          addMore: false,
          addMore2: false,
          limitModal: false,
          deviatedTherapyModal: false,
          therapy_limits: {},
          temp_therapy_limit: {},
          therapy_columns: {
            date: true,
            type_of_Therapy: true,            
            deviation: true,
            pressure_before: true,
            weight_before: true,
            day_UF: true,
            machine_UF: true,
            total_UF: true,
            drain_appearence: true,
            exit_site: false,
            pain_score: true,
            edit: true,
            therapy: true,
            approx: false,
            pressure_after: false,
            rate_before: false,
            rate_after: false,
            weight_after: false,
            avg_time: false,
            notes: false,
          },
          currColumnPage: 0,
          visibleColumnCnt: 12,
          fixedColumnCnt: 1,
          filters: {
            type: { label: "All Therapies", value: "all" },
            day_filters: { value: "last_week", label: "Last week" }
          },
        },
        diet: {
          MealDetailsModal: false,
          diet_logs: [],
          filters: {
            daysFilter: { value: "last_week", label: "Last week" }
          }
        },
        overview: {
          editPatientModal: false,
          overviewData: {},
          currentAttachemnts: "",
          dateRange: { value: "last_week", label: "Last week" },
        },
        medication: {
          tab: 0,
          approvalModal: false,
          prescriptionModal: false,
          attachmentModal: false,
          filterByStatus: { label: "All", value: "all" }
        },
        vitals: {
          tab: 1,
          rangeModal: false,
          graphModal: false,
          patientVitals: [],
          daysFilter: { value: "last_week", label: "Last week" },
          columns: {
            date: true,
            wieght: true,
            body_temperature: true,
            bp: true,
            blood_pressure_dia: true,
            heart_rate: true,
            respiratory_rate: true,
            spo2: true,
            blood_glucose: true,
            bmi: true,
          },
          vitalLimits: {
            wieght: {
              min: 48,
              max: 62,
            },
            body_temperature: {
              min: 36.6,
              max: 36.6,
            },
            body_pressure: {
              min: 145,
              max: 145,
            },
            bp: {
              min: 145,
              max: 145,
            },
            blood_pressure_dia: {
              min: 86,
              max: 86,
            },
            heart_rate: {
              min: 150,
              max: 150,
            },
            respiratory_rate: {
              min: 18,
              max: 18,
            },
            spo2: {
              min: 96,
              max: 96,
            },
            blood_glucose: {
              min: 230,
              max: 230,
            },
          },
        },
        symptoms: {
          editColumnModal: false,
          attachmentModal: false,
          tab: 3,
          columns: {
            reportingLimits: true,
            date: true,
            fatigue: true,
            others:true,
            sleeplessness: true,
            swelling: true,
            shortness_of_breath: true,
            muscle_cramps: true,
            nausea: false,
            loss_of_apetite: false,
            itching: false,
            constipation: false,
            dizziness: false,
            stress: false,
          },
          currColumnPage: 0,
          visibleColumnCnt: 8,
          fixedColumnCnt: 2,
          reportingLimits: {
            others: { name: { label: "Moderate", value: "Moderate" } },
            fatigue: { name: { label: "Moderate", value: "Moderate" } },
            sleeplessness: { name: { label: "Moderate", value: "Moderate" } },
            swelling: { name: { label: "Moderate", value: "Moderate" } },
            shortness_of_breath: { name: { label: "Moderate", value: "Moderate" } },
            muscle_cramps: { name: { label: "Moderate", value: "Moderate" } },
            nausea: { name: { label: "Moderate", value: "Moderate" } },
            loss_of_apetite: { name: { label: "Moderate", value: "Moderate" } },
            itching: { name: { label: "Moderate", value: "Moderate" } },
            constipation: { name: { label: "Moderate", value: "Moderate" } },
            dizziness: { name: { label: "Moderate", value: "Moderate" } },
            stress: { name: { label: "Moderate", value: "Moderate" } },
          },
          patientSymtoms: [],
          filters: {
            daysFilter: { value: "last_week", label: "Last week" }
          }
        },
        notes: {
          filters: {
            types: "all",
          },
          attachmentModal: false,
          filterd_data: [],
          notesList: [],
          datePoints: [],
          monthFilter: [],
          selected_date: "",
          index: 0,
        },
        notifications: {
          patientDetails: {},
          setting: {
            vitals: [],
            therapy: [],
            symptoms: []
          }
        }
      },
      admin: {
        draftPatientSearch: "",
        patient: {
          filters: {
            patientStatus: { value: 'all', label: 'All' },
            actualPatientSearch: "",
            patientType: ""
          }
        },
        clinician: {
          filters: {
            clinicianStatus: { value: 'all', label: 'All' },
            clinicianSearch: "",
            clinicianJobTitle: ""
          },
          allClinicians: []
        }
      },
    },
    communication: {
      chat_key: "",
    },
    clinicianPortal: {
      clinician_search: "",
      clinician_table: "",
      clinician_view: {
        profile: {},
        permissions: [],
        assignedPatients: {
          patientsTable: [],

        }
      },
      saveClinician: {
        profile: {},
        permissions: []
      },
      updateClinician: {}
    },
    patientPortal: {
      patient_search: "",
      patient_table: [],
      patient_index: 0,
      patient_view: {},
      savepatient: {
        profile: {},
        patient_limit: {
          vitals: {},
          symptoms: {},
        },
        prescriptions: [],
      },
      save_patient: {},
      savedraftpatient: [],
      edit_physician: {},
      resendInvitedialog: false,
      re_generatepwd: false,
      phiPatient: {
        userCredintials: {
          username: "",
          password: ""
        },
        phidialog: false
      }

    },
    administratorSummary: {
      clinicId: "",
      clinicInfo: {},
      piechart: {
        ckd: 0,
        pd: 0,
      },
      clinicstats: [],
      addClinician: [],
    },
    mdmsData: {
      vitalmdms: {
        vitalsmdmsUnits: {
          weight: { label: "Kg", value: "Kg" },
          body_temperature: { label: "C", value: "C" },
          blood_glucose: { label: "mmol/L", value: "mmol/L" },
          blood_pressure: { label: "mmHg", value: "mmHg" },
          diastolic_blood_pressure: { label: "mmHg", value: "mmHg" },
          heart_rate: { label: "bpm", value: "bpm" },
          respiratory_rate: { label: "bpm", value: "bpm" },
          spo2: { label: "%", value: "%" },
          height: { label: "m", value: "m" },
        },
        vital_limits: {
          weight_limit: {
            min: "58",
            max: "62",
          },
          body_temperature_limit: {
            min: "32",
            max: "42",
          },
          blood_pressure_limit: {
            min: "40",
            max: "260",
          },
          diastolic_blood_pressure_limit: {
            min: "40",
            max: "260",
          },
          body_pressure_limit: {
            min: "86",
            max: "86",
          },
          heart_rate_limit: {
            min: "40",
            max: "180",
          },
          respiratory_rate_limit: {
            min: "8",
            max: "35",
          },
          spo2_limit: {
            min: "88",
            max: "100",
          },
          blood_glucose_limit: {
            min: "11",
            max: "33",
          },
        }
      },
      symptoms_mdms: {
        symptoms_limit: {
          others: { label: "Mild", value: 3 },
          fatigue: { label: "Mild", value: 3 },
          sleeplessness: { label: "Mild", value: 3 },
          swelling: { label: "Mild", value: 3 },
          shortness_of_breath: { label: "Mild", value: 3 },
          muscle_cramps: { label: "Mild", value: 3 },
          nausea: { label: "Mild", value: 3 },
          loss_of_apetite: { label: "Mild", value: 3 },
          itching: { label: "Mild", value: 3 },
          constipation: { label: "Mild", value: 3 },
          dizziness: { label: "Mild", value: 3 },
          stress: { label: "Mild", value: 3 },
        },
      },
      therapy_mdms: {
        therapy_limit: {
          ultrafiltration_limit: {
            min: "-2500",
            max: "2500",
          },
          exit_site: { value: "CLEAN_AND_DRY", label: "Clean And Dry" },
          drain_color: { value: "#F7F2B5", label: "Light Yellow" },
          drain_clarity: { value: "SLIGHTLY_CLOUDY", label: "Slightly Cloudy" },
          drain_foreign_object: { value: "FIBRIN", label: "Fibrin" },
          pain_score: { value: "3", label: "3" },
        },
      },
      dateTimeFormat: {
        dateformat: { value: "DD-MMM-YYYY", label: "DD-MMM-YYYY" },
        timeformat: { value: "hh:mm a", label: "12 Hours" },
      },
      nameFormat: { value: "last_name_first", label: "Last name first" },
      default_notifications: {
        vitals: [
          {
            "frequency": { "label": 3, "value": 3 },
            "max": 62,
            "min": 58,
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "Weight Limit", "value": "weight_limit" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            "limit": "32-42",
            "max": 42,
            "min": 32,
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "Body Temperature Limit", "value": "body_temperature_limit" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            "limit": "40-260",
            "max": 260,
            "min": 40,
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "blood pressure limit", "value": "blood_pressure_limit" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            limit: "40-260",
            "max": 260,
            "min": 40,
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "diastolic blood pressure limit", "value": "diastolic_blood_pressure_limit" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            limit: "86-86",
            "max": 86,
            "min": 86,
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "body pressure limit", value: "body_pressure_limit" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            limit: "40-180",
            "max": 180,
            "min": 40,
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "heart rate limit", "value": "heart_rate_limit" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            limit: "8-35",
            "max": 35,
            "min": 8,
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            vital: { "label": "respiratory rate limit", "value": "respiratory_rate_limit" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            limit: "88-100",
            "max": 100,
            "min": 88,
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            vital: { "label": "spo2 limit", "value": "spo2_limit" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            limit: "11-33",
            "max": 33,
            "min": 11,
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            vital: { "label": "blood glucose limit", "value": "blood_glucose_limit" }
          }
        ],
        "symptoms": [
          {
            "frequency": { "label": 3, "value": 3 },
            "limit": "Mild",
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "others", "value": "others" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            "limit": "Mild",
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "fatigue", "value": "fatigue" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            "limit": "Mild",
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "sleeplessness", "value": "sleeplessness" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            "limit": "Mild",
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "swelling", "value": "swelling" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            "limit": "Mild",
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "shortness of breath", "value": "shortness_of_breath" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            "limit": "Mild",
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "muscle cramps", "value": "muscle_cramps" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            "limit": "Mild",
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "nausea", "value": "nausea" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            "limit": "Mild",
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "loss of apetite", "value": "loss_of_apetite" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            "limit": "Mild",
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "itching", "value": "itching" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            "limit": "Mild",
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "constipation", "value": "constipation" }
          },
          {
            "frequency": { "label": 3, "value": 3 },
            "limit": "Mild",
            "period": { "value": 2, "label": "Days" },
            "toNotify": true,
            "vital": { "label": "dizziness", "value": "dizziness" }
          },
          { "vital": { "label": "stress", "value": "stress" }, "limit": "Mild", "frequency": { "label": 3, "value": 3 }, "period": { "value": 2, "label": "Days" }, "toNotify": true }
        ],
        "therapy": {
          "ultrafiltration_limit": {
            "frequency": {
              "label": 3,
              "value": 3
            },
            "limit": {
              "label": "58-62",
              "value": "58-62"
            },
            "max": 62,
            "min": 58,
            "period": {
              "value": 2,
              "label": "Days"
            },
            "toNotify": true,
            "vital": {
              "label": "ultraFiltration",
              "value": "ultraFiltration"
            }
          },
          "exit_site": {
            "frequency": {
              "label": 3,
              "value": 3
            },
            "limit": {
              "value": "CLEAN_AND_DRY",
              "label": "CLEAN AND DRY"
            },
            "period": {
              "value": 2,
              "label": "Days"
            },
            "toNotify": true,
            "vital": {
              "label": "Exit Site",
              "value": "exitSite"
            }
          },
          "drain_color": {
            "frequency": {
              "label": 3,
              "value": 3
            },
            "limit": {
              "value": "#F7F2B5",
              "label": "Light yellow"
            },
            "period": {
              "value": 2,
              "label": "Days"
            },
            "toNotify": true,
            "vital": {
              "label": "Colour",
              "value": "colour"
            }
          },
          "drain_clarity": {
            "frequency": {
              "label": 3,
              "value": 3
            },
            "limit": {
              "value": "SLIGHTLY_CLOUDY",
              "label": "Slightly Cloudy"
            },
            "period": {
              "value": 2,
              "label": "Days"
            },
            "toNotify": true,
            "vital": {
              "label": "Clarity",
              "value": "clarity"
            }
          },
          "drain_foreign_object": {
            "frequency": {
              "label": 3,
              "value": 3
            },
            "limit": {
              "value": "FIBRIN",
              "label": "FIBRIN"
            },
            "period": {
              "value": 2,
              "label": "Days"
            },
            "toNotify": true,
            "vital": {
              "label": "Foreign Object",
              "value": "foreignObject"
            }
          },
          "pain_score": {
            "frequency": {
              "label": "3",
              "value": "3"
            },
            "limit": {
              "value": "3",
              "label": "3"
            },
            "period": {
              "value": 2,
              "label": "Days"
            },
            "toNotify": true,
            "vital": {
              "label": "Pain Score",
              "value": "painScore"
            }
          }
        }
      },
      "flaggingSystem": [
        {
          "id": 0,
          "flag": { "label": "redflag", "value": "redflag" },
          "category": "High Priority"
        },
        {
          "id": 1,
          "flag": { "label": "blueflag", "value": "blueflag" },
          "category": "CKD High Priority"
        },
        {
          "id": 2,
          "flag": { "label": "orangeflag", "value": "orangeflag" },
          "category": "PD High Priority"
        },
        {
          "id": 3,
          "flag": { "label": "yellowflag", "value": "yellowflag" },
          "category": "Diabetic High Priority"
        },
        {
          "id": 4,
          "flag": { "label": "pinkflag", "value": "pinkflag" },
          "category": "Hypertensive High Priority"
        }
      ],
      "defaultPermissions": {
        "admin": [
          { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": true } },
          { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": true } },
          { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": true } },
          { "roleId": 3, "role": { "label": "Manage Limits and Notifications for patients", "value": true } },
          { "roleId": 4, "role": { "label": "Review Medical Records", "value": true } },
          { "roleId": 5, "role": { "label": "Manage and Communicate with patients", "value": true } }
        ],
        "doctor": [
            { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
            { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
            { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": true } },
            { "roleId": 3, "role": { "label": "Manage Limits and Notifications for patients", "value": true } },
            { "roleId": 4, "role": { "label": "Review Medical Records", "value": true } },
            { "roleId": 5, "role": { "label": "Manage and Communicate with patients", "value": true } }
        ],
        "nurse": [
            { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
            { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
            { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": true } },
            { "roleId": 3, "role": { "label": "Manage Limits and Notifications for patients", "value": true } },
            { "roleId": 4, "role": { "label": "Review Medical Records", "value": true } },
            { "roleId": 5, "role": { "label": "Manage and Communicate with patients", "value": true } }
        ],
        "dietition": [
            { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
            { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
            { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": false } },
            { "roleId": 3, "role": { "label": "Manage Limits and Notifications for patients", "value": false } },
            { "roleId": 4, "role": { "label": "Review Medical Records", "value": true } },
            { "roleId": 5, "role": { "label": "Manage and Communicate with patients", "value": true } }
        ],
        "pharmacists": [
            { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
            { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
            { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": false } },
            { "roleId": 3, "role": { "label": "Manage Limits and Notifications for patients", "value": false } },
            { "roleId": 4, "role": { "label": "Review Medical Records", "value": true } },
            { "roleId": 5, "role": { "label": "Manage and Communicate with patients", "value": true } }
        ]
      }
      // "defaultPermissions": {
      //   "admin": [
      //     { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": true } },
      //     { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": true } },
      //     { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": true } },
      //     { "roleId": 3, "role": { "label": "Create / Update Patient PD Prescription", "value": true } },
      //     { "roleId": 4, "role": { "label": "Create / Update Patient Medication Prescriptions", "value": true } },
      //     { "roleId": 5, "role": { "label": "Define / Update Reporting Thresholds", "value": true } },
      //     { "roleId": 6, "role": { "label": "Access AWAK Customer Care", "value": true } },
      //     { "roleId": 7, "role": { "label": "Review Patient Therapy Records", "value": true } },
      //     { "roleId": 8, "role": { "label": "Review Patient Vital Records", "value": true } },
      //     { "roleId": 9, "role": { "label": "Review Patient Diet Records", "value": true } },
      //     { "roleId": 10, "role": { "label": "Manage Appointment with Patient", "value": true } },
      //     { "roleId": 11, "role": { "label": "Communication with Patients", "value": true } },
      //     { "roleId": 12, "role": { "label": "Access AWAK Online Educational Portal", "value": true } }
      //   ],
      //   "doctor": [
      //     { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
      //     { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
      //     { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": true } },
      //     { "roleId": 3, "role": { "label": "Create / Update Patient PD Prescription", "value": true } },
      //     { "roleId": 4, "role": { "label": "Create / Update Patient Medication Prescriptions", "value": true } },
      //     { "roleId": 5, "role": { "label": "Define / Update Reporting Thresholds", "value": true } },
      //     { "roleId": 6, "role": { "label": "Access AWAK Customer Care", "value": true } },
      //     { "roleId": 7, "role": { "label": "Review Patient Therapy Records", "value": true } },
      //     { "roleId": 8, "role": { "label": "Review Patient Vital Records", "value": true } },
      //     { "roleId": 9, "role": { "label": "Review Patient Diet Records", "value": true } },
      //     { "roleId": 10, "role": { "label": "Manage Appointment with Patient", "value": false } },
      //     { "roleId": 11, "role": { "label": "Communication with Patients", "value": true } },
      //     { "roleId": 12, "role": { "label": "Access AWAK Online Educational Portal", "value": true } }
      //   ],
      //   "nurse": [
      //     { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
      //     { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
      //     { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": true } },
      //     { "roleId": 3, "role": { "label": "Create / Update Patient PD Prescription", "value": true } },
      //     { "roleId": 4, "role": { "label": "Create / Update Patient Medication Prescriptions", "value": true } },
      //     { "roleId": 5, "role": { "label": "Define / Update Reporting Thresholds", "value": true } },
      //     { "roleId": 6, "role": { "label": "Access AWAK Customer Care", "value": true } },
      //     { "roleId": 7, "role": { "label": "Review Patient Therapy Records", "value": true } },
      //     { "roleId": 8, "role": { "label": "Review Patient Vital Records", "value": true } },
      //     { "roleId": 9, "role": { "label": "Review Patient Diet Records", "value": true } },
      //     { "roleId": 10, "role": { "label": "Manage Appointment with Patient", "value": false } },
      //     { "roleId": 11, "role": { "label": "Communication with Patients", "value": true } },
      //     { "roleId": 12, "role": { "label": "Access AWAK Online Educational Portal", "value": true } }
      //   ],
      //   "dietition": [
      //     { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
      //     { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
      //     { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": false } },
      //     { "roleId": 3, "role": { "label": "Create / Update Patient PD Prescription", "value": false } },
      //     { "roleId": 4, "role": { "label": "Create / Update Patient Medication Prescriptions", "value": false } },
      //     { "roleId": 5, "role": { "label": "Define / Update Reporting Thresholds", "value": false } },
      //     { "roleId": 6, "role": { "label": "Access AWAK Customer Care", "value": true } },
      //     { "roleId": 7, "role": { "label": "Review Patient Therapy Records", "value": true } },
      //     { "roleId": 8, "role": { "label": "Review Patient Vital Records", "value": true } },
      //     { "roleId": 9, "role": { "label": "Review Patient Diet Records", "value": true } },
      //     { "roleId": 10, "role": { "label": "Manage Appointment with Patient", "value": false } },
      //     { "roleId": 11, "role": { "label": "Communication with Patients", "value": true } },
      //     { "roleId": 12, "role": { "label": "Access AWAK Online Educational Portal", "value": true } }
      //   ],
      //   "pharmacists": [
      //     { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
      //     { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
      //     { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": false } },
      //     { "roleId": 3, "role": { "label": "Create / Update Patient PD Prescription", "value": false } },
      //     { "roleId": 4, "role": { "label": "Create / Update Patient Medication Prescriptions", "value": true } },
      //     { "roleId": 5, "role": { "label": "Define / Update Reporting Thresholds", "value": false } },
      //     { "roleId": 6, "role": { "label": "Access AWAK Customer Care", "value": true } },
      //     { "roleId": 7, "role": { "label": "Review Patient Therapy Records", "value": true } },
      //     { "roleId": 8, "role": { "label": "Review Patient Vital Records", "value": true } },
      //     { "roleId": 9, "role": { "label": "Review Patient Diet Records", "value": false } },
      //     { "roleId": 10, "role": { "label": "Manage Appointment with Patient", "value": false } },
      //     { "roleId": 11, "role": { "label": "Communication with Patients", "value": true } },
      //     { "roleId": 12, "role": { "label": "Access AWAK Online Educational Portal", "value": true } }
      //   ]
      // }
    },
    dashboard: {
      patient_groups: {
        ckdCount: 0,
        drainSite: 0,
        exitSite: 0,
        pdCount: 0,
      },
    },
    clinic: {
      isPHI: false
    },
    resetPasswordScreen: {
      newPassword: "",
      confirmPassword: "",
      passwordConfig: {
        newPasswordFlag: true,
        reEnterPassworFlag: true
      }
    },
    tempErrors: {
      addPatient: {
        isPHIError: false,
        genderError: false,
        heightError: false,
        ckdStageError: false,
        dobError: false,
        eGFRError: false,
        typeOfTheorpyError: false
      }
    },
    notification: {
      NotificationAcknowledge: [],
      NotificationPending: [],
      filters: {
        pendingAppointmentsFilters: { value: 'all', label: 'All pending' },
        pendingNotificationsFilters: { value: 'all', label: 'All Types' },
        acknowledgedAppointmentsFilters: { value: "last_week", label: "Last week" },
        acknowledgedNotificationsFilters: { value: 'all', label: 'All Types' }
      }
    },
    selectedLanguage: window.localStorage.getItem("selectedLanguage") || "en",
  },
};

const screenConfiguration = (state = intialState, action) => {
  switch (action.type) {
    case screenActionTypes.PREPARE_FINAL_OBJECT:
      const updatedPreparedFinalObject = prepareFinalObject(
        state.preparedFinalObject,
        action.jsonPath,
        action.value
      );
      return {
        ...state,
        preparedFinalObject: updatedPreparedFinalObject,
      };
    default:
      return state;
  }
};

export default screenConfiguration;
