import axios from "axios";
import { addQueryArg, backendHosts, localStorageGet, getDateInEpoch } from "./commons";
import { prepareFinalObject } from "../ui-redux/screen-configuration/actions";
import store from "../ui-redux/store";

const getToken = () => {
  const userInfo = JSON.parse(localStorage.getItem("user-info")) || {};
  return userInfo.token;
};

const createAxios = (url) => {
  const newInstance = axios.create({
    baseURL: url,
    // window.location.origin,
    headers: {
      "Content-Type": "application/json",
    }
  });

  newInstance.interceptors.response.use(
    (config) => { return config; },
    (error) => {
      const originalRequest = error.config;
      if (error && error.response.status === 403 && !originalRequest._retry) {
        if (localStorageGet('loggedIn') === 'true') {
          originalRequest._retry = true;
          let baseURL = `${backendHosts().AUTH_SERVICE}`;
          baseURL = baseURL + "auth-service/v1/_get_new_token";

          const authToken = localStorageGet("userInfo", "OAuthDetails.refreshToken");
          const requestId = localStorageGet("userInfo", "User.uuid");
          const clinicUUID = localStorageGet("userInfo", "User.clinic_uuid");
          let RequestInfo = {
            apiId: "AWAK_WEB",
            ver: "V1",
            ts: `${getDateInEpoch()}`,
            action: "_get_new_token",
            did: "1",
            key: "",
            msgId: "1605595169022|en_IN",
            requesterId: requestId,
            authToken: authToken,
            clinicUUID,
          };

          let requestBody = {};

          requestBody = {
            RequestInfo,
            ...requestBody
          }
          return axios.post(
            baseURL,
            requestBody
          ).then(res => {
            if (res.status === 200) {
              const myStorage = window.localStorage;
              myStorage.setItem('userInfo', JSON.stringify(res.data));
              sessionStorage.setItem('userInfoSession', JSON.stringify(res.data));
              let dataParse = JSON.parse(originalRequest.data);
              dataParse.RequestInfo.authToken = res.data.OAuthDetails.authToken;
              originalRequest.data = JSON.stringify(dataParse);
              return newInstance(originalRequest);
            }
          }).catch(
            function (error) {
              if (error.response.status === 403) {
                store.dispatch(prepareFinalObject("errorChk", true));
                return Promise.reject(error)
              }
            }
          )
        }
      }
      return Promise.reject(error);
    }
  );

  return newInstance;
}

let axiosInstances = {
  instanceOne: createAxios(`${backendHosts().AUTH_SERVICE}`),
  instanceTwo: createAxios(`${backendHosts().USER_SERVICE}`),
  instanceThree: createAxios(`${backendHosts().PATIENT_SERVICE}`),
  instanceFour: createAxios(`${backendHosts().CLINICIAN_SERVICE}`),
  instanceFive: createAxios(`${backendHosts().NOTES_SERVICE}`),
  instanceSix: createAxios(`${backendHosts().VITALS_SERVICE}`),
  instanceSeven: createAxios(`${backendHosts().PATIENT_MEDICATION}`),
  instanceEight: createAxios(`${backendHosts().CLINIC_SERVICE}`),
  instanceNine: createAxios(`${backendHosts().CONFIG_SERVICE}`),
  instanceTen: createAxios(`${backendHosts().APPOINTMENT_SERVICE}`),
  instanceEleven: createAxios(`${backendHosts().SYMPTOMS_SERVICE}`),
  instanceTwelve: createAxios(`${backendHosts().MOCK_API}`),
  instanceThirteen: createAxios(`${backendHosts().NOTIFICATION_SERVICE}`),
  instanceFourteen: createAxios(`${backendHosts().THERAPY_SERVICE}`),
  instanceFifteen: createAxios(`${backendHosts().DIET_SERVICE}`)
};

const wrapRequestBody = (requestBody) => {
  return requestBody;
};

export const httpRequest = async ({
  method = "get",
  endPoint,
  queryObject = [],
  requestBody = {},
  formData = {},
  hasClinicUUID = true,
  instance = "instanceOne",
  hasSpinner = true,
}) => {
  if (hasSpinner) {
    store.dispatch(prepareFinalObject("spinner", true));
  }
  let apiError = "Api Error";
  var headerConfig = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  endPoint = addQueryArg(endPoint, queryObject);
  var response;
  try {
    switch (method) {
      case "post":
        response = await axiosInstances[instance].post(
          endPoint,
          wrapRequestBody(requestBody),
          headerConfig
        );
        break;
      case "put":
        response = await axiosInstances[instance].put(
          endPoint,
          formData
          // wrapRequestBody(requestBody),
          // headerConfig
        );
        break;
      default:
        response = await axiosInstances[instance].get(endPoint);
    }
    const responseStatus = parseInt(response.status, 10);
    if (hasSpinner) {
      store.dispatch(prepareFinalObject("spinner", false));
    }
    if (responseStatus === 200 || responseStatus === 201) {
      return response.data;
    }
  } catch (error) {
    const { data, status } = error.response || {};
    console.log(data,"data from api");
    console.log(status,"status of api");
    if (status === 400 && data === "") {
      apiError = "INVALID_TOKEN";
    } else {
      apiError = data;
    }
    if (hasSpinner) {
      store.dispatch(prepareFinalObject("spinner", false));
    }
  }

  return apiError;
};