import React, { useCallback, useEffect, useRef, useState } from 'react';
import PubNub from 'pubnub';
import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { Carousel } from "react-responsive-carousel";
import { PubNubProvider, usePubNub } from 'pubnub-react';
import { getDoctorRoles, localStorageGet, mapDispatchToProps, wrapRequestBody, capitalize, pushClevertapEvent } from '../../ui-utils/commons';
import Highlighter from "react-highlight-words";
import { httpRequest } from "./../../ui-utils/api";
import moment from 'moment';
import { connect } from "react-redux";
import './index.css';

// eslint-disable-next-line
let regex = /<br\s*[\/]?>/gi;

const MESSAGE_TYPE = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE'
}

const pubnub = new PubNub({
  publishKey: 'pub-c-32cc5d68-ee4e-4f8f-aae3-325dbb699529',
  subscribeKey: 'sub-c-a1736ac4-000f-11ec-b0c0-62dfa3a98328',
  authKey: localStorageGet("userInfo", "chatSDKAuthDetails.aiAuthKey"),
  uuid: localStorageGet("userInfo", "User.uuid")
});

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress 
          variant="determinate" 
          {...props} 
        />
      </Box>
      <Box sx={{ minWidth: 25 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(props.value,)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

let tempTime = '';
const Chat = ({searchText,pChatid,chatName,chatPriority,setAppData,prevMessages,height,oldAppointment,replyMsg,replyCallBack,communication,dateTimeFormat,channelName,vidMsg}) => {
  const { dateformat = "", timeformat = "" } = dateTimeFormat || {};
  const pubnub = usePubNub();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [preview, setPreview] = useState(false);
  const [src, setSrc] = useState('');
  const [prevMappedMessages, setPrevMappedMessages] = useState([]);
  const [mappedUrlResponse, setMappedUrlResponse] = useState({});
  const messageEl = useRef(null);
  const messageInputRef = useRef(null);
  let channels = [];
  if (localStorageGet("userInfo", "chatSDKAuthDetails.aiChannels")) {
    channels = isEmpty(channelName) ?
      [localStorageGet("userInfo", "User.clinic_uuid") + '-' + pChatid + '-ai-chat']
      :
      [channelName]
  };

  useEffect(() => {
    pubnub.addListener({
      message: messageEvent => {
        if (messageEvent.timetoken !== tempTime) {
          tempTime = messageEvent.timetoken;
          // if(typeof communication.newMessages == undefined){
          //   setAppData('communication.newMessages', []);
          // }
          console.log("Before ", communication.oldAppointment, communication.newMessages, messageEvent.length);

          if (oldAppointment) {
            communication.oldAppointment = [...communication.oldAppointment, messageEvent];
            setAppData('communication.oldAppointment', communication.oldAppointment);
            setAppData('communication.newMessages', communication.oldAppointment);
          } else {
            communication.newMessages = [...communication.newMessages, messageEvent];
            setAppData('communication.oldAppointment', communication.newMessages);
            setAppData('communication.newMessages', communication.newMessages);
          }

          console.log("After ", communication.oldAppointment, communication.newMessages, messageEvent.length);
        }
      },
    });
    pubnub.subscribe({
      channels: channels,
      channel_group: localStorageGet("userInfo", "User.clinic_uuid")
    });
    // eslint-disable-next-line 
  }, [messages]);

  useEffect(() => {
    if (messageEl) {
      messageEl.current.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }

    pubnub.messageCounts({
      channels: channels,
      channelTimetokens: ['15518041524300251']
    }).then((response) => {
    }).catch((error) => {
      // handle error
    }
    );
    // eslint-disable-next-line
  }, []);

  const [responseFromServer, setResponseFromServer] = useState(false)
  const [imageLoader, setImageLoader] = useState(false)
  const [imageLoaderProgress, setImageLoaderProgress] = useState(0)


  const getPresinedPostUrl = async () => {

    const clinicUUID = localStorageGet("userInfo", "User.clinic_uuid")
    // hardcoded values to particular user
    const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiZjk2YTBmZTAtZTVkMC0xMWVlLWE1MzUtOWQ0Y2JjZWU4NmY0IiwiaWQiOiJmOTZhMGZlMS1lNWQwLTExZWUtYTUzNS05ZDRjYmNlZTg2ZjQiLCJyb2xlcyI6W3sibmFtZSI6IlBBVElFTlQiLCJ1dWlkIjoiMTIzNDU2Nzg5MDE0In1dLCJjbGluaWNfdXVpZCI6IjE0MTY2NzcwLThjMjMtMTFlZC1iNmY2LWFkNDJiMWRjNmQwMiIsImNyZWF0ZWRBdCI6MTcyNDY3NTk0ODczMiwiaWF0IjoxNzI0Njc1OTQ4LCJleHAiOjE3MjQ5NDU5NDh9.hklk2PPYbwC-iPZAYu5SRDLrW2zguafr-xqOq0yEZWo"
    const requesterId = "f96a0fe0-e5d0-11ee-a535-9d4cbcee86f4"

    const requestBody = {
      clinicUUID: clinicUUID,
      RequestInfo: {
          ver: "V1",
          apiId: "AWAK_IOS",
          app_version: 2.0999999046325684,
          did: "10D71314-B677-4B55-84EA-30FA6A8C5D4E",
          clinicUUID: clinicUUID,
          ts: "Tue, 16 Jul 2024 15:10:57 +0530IST",
          action: "_create",
          authToken: authToken,
          requesterId: requesterId,
          msgId: "1721122.8577892152|en_IN"
      },
      PresignedPostURL: {
          moduleName: "chat",
          filename: "swelling.jpg"
        }
    }

    try {
      const response = await fetch('https://awak-wk-config-micro-service-staging.awakpd.com/config-services/v1/_presigned_post_url', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody)
      })
      const data = await response.json();
      return data;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Re-throw error to be handled by caller
    }

  }

  const uploadImageToPresignedPostUrl = async (presigned_response_value, message) => {
    const presigned_post_url = presigned_response_value.PresignedPostURL.presignedPostURL

    try {
      const response = await fetch(presigned_post_url, {
        method: 'PUT',
        body: message,
        headers: {
          'Content-Type': message.type, // Ensure the correct MIME type is set
        },
      });
  
      console.log('file upload response: ', response)
      return {
        response,
        file_path: presigned_response_value.PresignedPostURL.filePath
      }
    } catch (error) {
      console.error('Error uploading image', error);
      return false;  // Return false if there's an error during the upload
    }
  }
  const getPresignedUrl = async (file_path) => {

    const clinicUUID = localStorageGet("userInfo", "User.clinic_uuid")
    // hardcoded values to particular user
    const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiZjk2YTBmZTAtZTVkMC0xMWVlLWE1MzUtOWQ0Y2JjZWU4NmY0IiwiaWQiOiJmOTZhMGZlMS1lNWQwLTExZWUtYTUzNS05ZDRjYmNlZTg2ZjQiLCJyb2xlcyI6W3sibmFtZSI6IlBBVElFTlQiLCJ1dWlkIjoiMTIzNDU2Nzg5MDE0In1dLCJjbGluaWNfdXVpZCI6IjE0MTY2NzcwLThjMjMtMTFlZC1iNmY2LWFkNDJiMWRjNmQwMiIsImNyZWF0ZWRBdCI6MTcyNDY3NTk0ODczMiwiaWF0IjoxNzI0Njc1OTQ4LCJleHAiOjE3MjQ5NDU5NDh9.hklk2PPYbwC-iPZAYu5SRDLrW2zguafr-xqOq0yEZWo"
    const requesterId = "f96a0fe0-e5d0-11ee-a535-9d4cbcee86f4"

    const requestBody = {
      "PresignedGetURL": {
          "filepath": file_path
      },
      "RequestInfo": {
          "ts": "Tue, 16 Jul 2024 17:40:43 +0530IST",
          "authToken": authToken,
          "app_version": 2.0999999046325684,
          "ver": "V1",
          "apiId": "AWAK_IOS",
          "did": "0297EC19-2CBB-40B3-B78F-F2EEE5B8A7CA",
          "msgId": "1721131.84360479|en_IN",
          "requesterId": requesterId,
          "clinicUUID": clinicUUID,
          "action": "_create"
      }
  }

    try {
      const response = await fetch('https://awak-wk-config-micro-service-staging.awakpd.com/config-services/v1/_presigned_get_url', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody)
      })
      const data = await response.json();
      console.log('get presigned url response: ', data)
      return data.PresignedGetURL;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Re-throw error to be handled by caller
    }
  }

  const sendMessage = useCallback(
    async (message, message_type) => {
      let replyChk = localStorage.getItem('localReply') ? JSON.parse(localStorage.getItem('localReply')) : {};
      if(message_type === MESSAGE_TYPE.TEXT){
        await pubnub.publish({
          channel: channels[0],
          message: {
            user: {
              uuid: localStorageGet("userInfo", "User.uuid"),
              Name: capitalize(localStorageGet("userInfo", "User.firstname")),
              role: capitalize(localStorageGet("userInfo", "User.roles").filter(item => item.uuid !== '123456789018' && item.uuid !== '123456789013')[0].name),
              ImageURL: '',
              reply: replyChk
            },
            messagetype: 'TEXT',
            textmessage: message,
            additionalInfo: {
              notesIDlist: [],
              therapyRecords: []
            }
          },
          meta: {
            "uuid": localStorageGet("userInfo", "User.uuid"),
            "pChatid": pChatid,
            "chatName": chatName,
            "chatPriority": chatPriority
          }
        });
        setInput('');
        replyCallBack(false);
        localStorage.setItem('localReply', '');
      } else if(message_type === MESSAGE_TYPE.IMAGE) {

        // post to presigned URL
        if(message && message.length >0) {
          const imageUrl = message // here message = getPressignedURL_Link
          // send to pubnub
          await pubnub.publish({
              channel: channels[0],
              message: {
                type: 'image',
                content: {
                  url: imageUrl,
                },
                user: {
                  uuid: localStorageGet("userInfo", "User.uuid"),
                  Name: capitalize(localStorageGet("userInfo", "User.firstname")),
                  role: capitalize(localStorageGet("userInfo", "User.roles").filter(item => item.uuid !== '123456789018' && item.uuid !== '123456789013')[0].name),
                },
              },
              meta: {
                "uuid": localStorageGet("userInfo", "User.uuid"),
                "pChatid": pChatid,
                "chatName": chatName,
                "chatPriority": chatPriority
              }
            },
            (status, response) => {
              if (status.error) {
                console.log('Error publishing:', status);
              } else {
                console.log('Image Message Published:', response);
              }
            }
          );
          setInput('');
          replyCallBack(false);
          localStorage.setItem('localReply', '');
        }

      }
    },
    // eslint-disable-next-line
    [pubnub, setInput, pChatid]
  );

  const sendMessageToServer = async (input, channelName, pChatid, message_type) => {
    setTimeout(() => {
      setResponseFromServer(true)
    }, 300);
    const clinicUUID = localStorageGet("userInfo", "User.clinic_uuid")
    // hardcoded values to particular user
    const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiZjk2YTBmZTAtZTVkMC0xMWVlLWE1MzUtOWQ0Y2JjZWU4NmY0IiwiaWQiOiJmOTZhMGZlMS1lNWQwLTExZWUtYTUzNS05ZDRjYmNlZTg2ZjQiLCJyb2xlcyI6W3sibmFtZSI6IlBBVElFTlQiLCJ1dWlkIjoiMTIzNDU2Nzg5MDE0In1dLCJjbGluaWNfdXVpZCI6IjE0MTY2NzcwLThjMjMtMTFlZC1iNmY2LWFkNDJiMWRjNmQwMiIsImNyZWF0ZWRBdCI6MTcyNDY3NTk0ODczMiwiaWF0IjoxNzI0Njc1OTQ4LCJleHAiOjE3MjQ5NDU5NDh9.hklk2PPYbwC-iPZAYu5SRDLrW2zguafr-xqOq0yEZWo"
    const requesterId = "f96a0fe0-e5d0-11ee-a535-9d4cbcee86f4"
    const aiAuthKey = localStorageGet("userInfo", "chatSDKAuthDetails.aiAuthKey")
    const aiChannels = isEmpty(channelName) ? 
      [localStorageGet("userInfo", "User.clinic_uuid") + '-' + pChatid + '-ai-chat'] 
        : 
      ['']

    let messageValue;
    if(message_type === MESSAGE_TYPE.TEXT){
      messageValue = {
        type: "text",
        text: input
      }
    } else if (message_type === MESSAGE_TYPE.IMAGE){
      messageValue = {
        type: "image",
        attachment: input.getPressignedURL_Link,
        file_path: input.file_path
      }
    }

    const requestBody = {
      RequestInfo: {
          ver: "V1",
          apiId: "AWAK_IOS",
          app_version: 2.0999999046325684,
          did: "10D71314-B677-4B55-84EA-30FA6A8C5D4E",
          clinicUUID: clinicUUID,
          ts: "Tue, 16 Jul 2024 15:10:57 +0530IST",
          action: "_create",
          authToken: authToken,
          requesterId: requesterId,
          msgId: "1721122.8577892152|en_IN"
      },
      patient_user_uuid: requesterId,
      channelDetails: {
          aiAuthKey: aiAuthKey,
          channel_id: aiChannels
      },
      message: messageValue
      // message: {
      //     type: "text",
      //     text: input // vitals
      //     // "text": "I had butter masala dosa with one scoop of vanilla icecream" // diet
      //     // ------------------------------------------------------------------------------
      //     // "type": "image",
      //     // "attachment": "https://awakpd-dev.s3.us-east-2.amazonaws.com/1223/1721814736618/chat/1721814736618_image_1721122858.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2PMNLOTRHIQUAD5Q%2F20240724%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240724T102004Z&X-Amz-Expires=100000&X-Amz-Signature=9f358c35218b6b40f4ccc1d36dada3cd3ce91508cb55a66f7a20850f9d06d4a2&X-Amz-SignedHeaders=host",
      //     // "file_path": "1223/1721813122805/chat/1721813122805_image_1721122858.png"
      //     // ------------------------------------------------------------------------------
      // }
    }

    try {
      const response = await fetch('https://awak-wk-appointment-micro-service-staging.awakpd.com/appointment-serivice/v1/SendChatToServer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        setResponseFromServer(false)
        console.log('data from sendMessageToServer: ', data)
        console.log(data);
      } else {
        console.error('Request failed with status', response.status);
      }
    } catch (error) {
      console.error('Request failed', error);
      setResponseFromServer(false)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !isEmpty(input)) {
      tempTime = '';
      sendMessage(input, MESSAGE_TYPE.TEXT);
      sendMessageToServer(input, channelName, pChatid, MESSAGE_TYPE.TEXT);

      // COMMUNICATION_NEW-CHAT event
      pushClevertapEvent("COMMUNICATION_NEW-CHAT")
    }
  }
  useEffect(() => {
    pubnub.history(
      {
        channel: channels,
        stringifiedTimeToken: true, // false is the default
        includeMeta: true
      }).then((response) => {
        var msgs = response.messages;
        if (msgs !== undefined && msgs.length > 0) {
          let messageGroup = response.messages.map(item => {
            return {
              chatName: item.meta && item.meta.chatName,
              chatPriority: item.meta && item.meta.chatPriority,
              pChatid: item.meta && item.meta.pChatid,
              uuid: item.meta && item.meta.uuid,
              message: item.entry,
              time: timeStamp(item.timetoken),
              token: item.timetoken
            }
          });

          if (communication.chatGroup) {
            if (communication.chatGroup.length !== messageGroup.length) {
              setAppData('communication.chatGroup', messageGroup);
            }
          } else {
            setAppData('communication.chatGroup', messageGroup);
          }
          //setAppData('communication.chatGroup', groups);
          if (!isEmpty(chatName)) {
            let oldEvent = [];
            messageGroup.forEach(item => {
              oldEvent.push({
                actualChannel: null,
                channel: channels[0],
                message: item.message,
                publisher: item.uuid,
                subscribedChannel: channels[0],
                timetoken: item.token,
                userMetadata: {
                  uuid: item.uuid,
                  pChatid: item.pChatid,
                  chatName: item.chatName,
                  chatPriority: item.chatPriority
                }
              })
            })

            if (messages.length === 0 || (messages.length > 0 && oldEvent.length > 0)) {
              setMessages(oldEvent);
              setAppData('communication.newMessages', oldEvent);
              setAppData('communication.oldAppointment', oldEvent);
            } else {
              setAppData('communication.newMessages', []);
              setAppData('communication.oldAppointment', []);
            }
          }
        } else {
          setAppData('communication.newMessages', []);
          setAppData('communication.oldAppointment', []);
        }

        // if 100 msgs were retrieved, there might be more; call history again
        // if (msgs.length === 100) {
        //   setCount({newCount:newCount*2});
        // }

      }).catch((error) => {
        setAppData('communication.newMessages', []);
        setAppData('communication.oldAppointment', []);
        console.log(error);
      });
    // eslint-disable-next-line
  }, [pChatid, channelName]);


  function timeStamp(string) {
    let ts = parseInt(string)
    const date = new Date(parseInt(ts) / 10000).getTime();
    return moment(date).format(dateformat && dateformat.value) + ' ' + moment(date).format(timeformat && timeformat.value);
    // return formatDefalutDate(moment(date), dateformat && dateformat.value) + defaultFormatTime(moment(date), timeformat && timeformat.value);
  };
  const uploadFile = async (e) => {
    setImageLoader(true)
    setImageLoaderProgress(20)

    // get presigned URL
    const presigned_response_value = await getPresinedPostUrl()
    setImageLoaderProgress(50)
    const postImageResponse = await uploadImageToPresignedPostUrl(presigned_response_value, e[0])
    setImageLoaderProgress(90)

    if(postImageResponse.response.status !== 200) {
      return
    }

    const getPressignedURL_Link = await getPresignedUrl(postImageResponse.file_path)

    setImageLoaderProgress(100)
    setImageLoader(false)

    // get Image URL
    sendMessage(getPressignedURL_Link, MESSAGE_TYPE.IMAGE)
    sendMessageToServer(
      {
        getPressignedURL_Link, 
        file_path:postImageResponse.file_path
      }, 
      channelName, 
      pChatid, 
      MESSAGE_TYPE.IMAGE
    )
  }
  async function getMappedUrl(mappedUrls) {
    let reqBody = {
      "PresignedGetURL": {
        "filepath": mappedUrls,
        "moduleName": "chat"
      }
    }
    const urlResponse = await httpRequest({
      endPoint: `config-services/v1/_presigned_get_url`,
      method: "post",
      instance: "instanceNine",
      requestBody: wrapRequestBody(reqBody, "_search"),
    });
    if (urlResponse.PresignedGetURL) {
      setMappedUrlResponse(urlResponse.PresignedGetURL);
    }
  }
  let mappedMessages = [];
  let mappedUrls = [];
  if (oldAppointment) {
    mappedMessages = communication.oldAppointment ? communication.oldAppointment : messages;
  } else {
    mappedMessages = communication.newMessages ? communication.newMessages : messages;
  }
  console.log('mappedMessages: ', mappedMessages)
  mappedMessages.forEach(item => {
    if ((item.message.messagetype || 'TEXT') !== 'TEXT') {
      mappedUrls.push(item.message.textmessage.split('.com/')[1].split('?')[0]);
    }
  });
  if (mappedUrls.length > 0 && JSON.stringify(mappedUrls) !== JSON.stringify(prevMappedMessages)) {
    setPrevMappedMessages(mappedUrls);
    getMappedUrl(mappedUrls);
  }

  useEffect(() => {
    if (messageEl.current) {
      messageEl.current.scroll({ top: messageEl.current.scrollHeight, behavior: 'smooth' });
    }
  }, [mappedMessages, responseFromServer, imageLoader, imageLoaderProgress]);

  return (

    <div>
      {vidMsg &&
        <div className='vidMessage'>
          Messages
        </div>
      }
      <Grid item xs={12} className={height ? '' : 'cardScroller1'}>
        <div
          style={{
            backgroundColor: 'white',
            height: height ? height + 'px' : '530px',
            overflow: 'scroll',
          }}
          ref={messageEl}
        >
          <Grid container>
            {mappedMessages.map((message, messageIndex) => {
              console.log('mappedMessages: ', mappedMessages)
              console.log('message.message: ', message.message)
              let textMessage = message.message.textmessage
              if(typeof textMessage === 'string'){
                textMessage = message.message.textmessage.replace(regex, '\n')
              } else {
                textMessage = ""
              }

              return (
                <Grid item style={message.message.user.uuid !== pChatid ? { 
                    width: "100%" 
                  } : {
                }}>
                  {message.message.user ?
                    message.message.user.uuid === pChatid ?
                      <Grid item xs={12} style={searchText && searchText.length > 0 && !message.message.textmessage.includes(searchText) ? { display: 'none' } : {}} className='sentMsg' key={messageIndex}>
                        <Grid container>
                          <Grid item xs={11}>
                            <Typography className='dietician_text'><span style={{ color: '#636363' }}>{message.message.user.role}</span> {message.message.user.Name}</Typography>
                          </Grid>
                          {/* {!oldAppointment &&
                            <Grid item xs={1} style={{ cursor: 'pointer' }} onClick={() => { replyCallBack(message) }}>
                              <img src='ui-assets/images/reply.svg' alt="add" width='18px' height='18px' />
                            </Grid>
                          } */}
                        </Grid>
                        {!isEmpty(message.message.user.reply) &&
                          <Grid container style={{ padding: '10px', background: '#efeeee94', borderRadius: '10px' }}>
                            <Grid item xs={12} style={{ fontFamily: 'PoppinsBold', color: '#e88686' }}>{message.message.user.reply.name}</Grid>
                            <Grid item xs={12}>

                              {message.message.user.reply.messagetype === 'image' ?
                                <div style={{ width: '100px', height: '100px', cursor: 'pointer' }} onClick={() => { setPreview(true); setSrc(message.message.user.reply.message) }}>
                                  <img src={message.message.content.url} height='100%' width='100%' alt='imageChat' />
                                </div>
                                :
                                message.message.user.reply.messagetype === 'AUDIO' ?
                                  <audio controls style={{ width: '216px', height: '22px' }}>
                                    <source src={message.message.user.reply.message} type="audio/ogg" />
                                  </audio>
                                  :
                                  message.message.user.reply.messagetype === 'VIDEO' ?
                                    <video width="200" height="150" controls>
                                      <source src={message.message.user.reply.message} type="video/ogg" />
                                    </video>
                                    :
                                    message.message.user.reply.message.replace(regex, '\n')
                              }
                            </Grid>
                          </Grid>
                        }
                        {message.message.type === 'image' ?
                            <div 
                              style={{ width: '100px', height: '100px', cursor: 'pointer' }} 
                              onClick={() => { setPreview(true); setSrc(message.message.content.url) }}
                            >
                              <img
                                src={message.message.content.url}
                                height='100%' width='100%'
                                alt='imageChat'
                              />
                            </div>
                          :
                          message.message.messagetype === 'AUDIO' ?
                            <audio controls style={{ width: '231px', height: '22px' }}>
                              <source
                                src={!isEmpty(mappedUrlResponse) ?
                                  mappedUrlResponse[message.message.textmessage.split('.com/')[1].split('?')[0]] :
                                  message.message.textmessage}
                                type="audio/ogg"
                              />
                            </audio>
                            :
                            message.message.messagetype === 'VIDEO' ?
                              <video width="200" height="150" controls>
                                <source
                                  src={!isEmpty(mappedUrlResponse) ?
                                    mappedUrlResponse[message.message.textmessage.split('.com/')[1].split('?')[0]] :
                                    message.message.textmessage}
                                  type="video/ogg"
                                />
                              </video>
                              :
                              <Typography className='chat_text'>
                                <Highlighter
                                  highlightClassName="highlight"
                                  searchWords={[searchText]}
                                  autoEscape={true}
                                  textToHighlight={message.message.textmessage.replace(regex, '\n')}
                                />
                              </Typography>
                        }
                        <Typography className='chat_time'>{timeStamp(message.timetoken)}</Typography>
                      </Grid>
                      :
                      <Grid 
                        item 
                        xs={12} 
                        style={searchText && searchText.length > 0 && !message.message.textmessage.includes(searchText) ? { display: 'none' } : {}} 
                        className={
                          message.message.user.uuid === localStorageGet("userInfo", "User.uuid") ? 
                          'receivedMsg2' 
                          : 
                          'receivedMsg1'
                        } 
                        key={messageIndex}
                      >

                        <Grid container className='chat-header'>
                          <Grid item xs={10}>
                            <Typography className='dietician_text'>
                              <span className='dietician'>
                                {/* {message.message.user.role} */}
                                User
                              </span>
                              &nbsp;{message.message.user.Name}
                            </Typography>
                          </Grid>
                        </Grid>
                        {!isEmpty(message.message.user.reply) &&
                          <Grid container style={{ padding: '10px', background: '#ceebef57', borderRadius: '10px' }}>
                            <Grid item xs={12} style={{ fontFamily: 'PoppinsBold', color: '#e88686' }}>{message.message.user.reply.name}</Grid>
                            <Grid item xs={12} >
                              {message.message.user.reply.messagetype === 'IMAGE' ?
                                <div style={{ width: '100px', height: '100px', cursor: 'pointer' }} onClick={() => { setPreview(true); setSrc(message.message.user.reply.message) }}>
                                  <img src={message.message.user.reply.message} height='100%' width='100%' alt='imageChat' />
                                </div>
                                :
                                message.message.user.reply.messagetype === 'AUDIO' ?
                                  <audio controls style={{ width: '216px', height: '22px' }}>
                                    <source src={message.message.user.reply.message} type="audio/ogg" />
                                  </audio>
                                  :
                                  message.message.user.reply.messagetype === 'VIDEO' ?
                                    <video width="200" height="150" controls>
                                      <source src={message.message.user.reply.message} type="video/ogg" />
                                    </video>
                                    :
                                    message.message.user.reply.message.replace(regex, '\n')
                              }
                            </Grid>
                          </Grid>
                        }
                        {message.message.messagetype === 'VIDEO' ?
                          <video width="200" height="150" controls>
                            <source
                              src={!isEmpty(mappedUrlResponse) ?
                                mappedUrlResponse[message.message.textmessage.split('.com/')[1].split('?')[0]] :
                                message.message.textmessage}
                              type="video/ogg" />
                          </video>
                          :
                          message.message.messagetype === 'AUDIO' ?
                            <audio controls style={{ width: '231px', height: '22px' }}>
                              <source
                                src={!isEmpty(mappedUrlResponse) ?
                                  mappedUrlResponse[message.message.textmessage.split('.com/')[1].split('?')[0]] :
                                  message.message.textmessage}
                                type="audio/ogg" />
                            </audio>
                            :
                            message.message.type === 'image' ?
                              <div 
                                style={{ width: '100px', height: '100px', cursor: 'pointer', marginBottom: "10px" }} 
                                onClick={() => { setPreview(true); setSrc(message.message.content.url) }}
                              >
                                <img
                                  src={message.message.content.url}
                                  style={{ borderRadius: "15px" }}
                                  height='100%' width='100%'
                                  alt='imageChat'
                                />
                              </div>
                              :
                              <Typography className='chat_text'>
                                <Highlighter
                                  highlightClassName="highlight"
                                  searchWords={[searchText]}
                                  autoEscape={true}
                                  // textToHighlight={message.message.textmessage.replace(regex, '\n')}
                                  textToHighlight={textMessage}
                                />
                              </Typography>
                        }

                        <Typography className='chat_time'>{timeStamp(message.timetoken)}</Typography>
                      </Grid>
                    :
                    ''
                  }
                </Grid>
              );

            })}
            {responseFromServer && (
              <div className='typingContainer'>
                <div className="typing">
                  <div className="dot"/>
                  <div className="dot"/>
                  <div className="dot"/>
                </div>
              </div>
            )}
            {imageLoader && (
              <Box sx={{ width: '100%' }}>
                <LinearProgressWithLabel 
                  value={imageLoaderProgress} 
                  sx={{
                    backgroundColor: '#089bab', // Background color for the track
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#089bab',
                    },
                  }}
                />
              </Box>
            )}
          </Grid>
        </div>
      </Grid>

      {replyMsg.reply &&
        <Grid container className='replyContainer' style={{ overflow: 'hidden', height: '50px' }}>
          <Grid item xs={11}>
            {replyMsg.name}
          </Grid>
          <Grid item xs={1} style={{ cursor: 'pointer', textAlign: 'end' }} onClick={() => replyCallBack(false)}>
            <img alt="close" width='20' src="ui-assets/images/close.svg" />
          </Grid>
          <Grid item xs={12} style={{ overflow: 'hidden' }}>{replyMsg.message}</Grid>
        </Grid>
      }

      {!oldAppointment && (
        <Grid
          onClick={() => {
            if (messageInputRef.current) {
              messageInputRef.current.focus();
            }
          }}
          container
          className="inputSection"
        >
          <input
            ref={messageInputRef}
            variant="outlined"
            className="textFields"
            placeholder="Type a message…"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button className="attachBtn">
            <input
              type="file"
              name="profileImage"
              id="profileImage"
              style={{ display: "none" }}
              onChange={(e) => uploadFile(e.target.files)}
            />
            <label for="profileImage" style={{ cursor: "pointer" }}>
              <img alt="attach" src="ui-assets/images/attach.svg" />
            </label>
          </button>
          <button
            className="sendBtn"
            onClick={(e) => {
              e.preventDefault();
              input && sendMessage(input, MESSAGE_TYPE.TEXT);
              input && sendMessageToServer(input, channelName, pChatid, MESSAGE_TYPE.TEXT);
            }}
          >
            <img alt="send" src="ui-assets/images/awak-ic-24-px-send.svg" />
          </button>
        </Grid>
      )}

      {preview && (
        <div className="previewContainer">
          <div
            onClick={() => {
              setPreview(false);
              setSrc("");
            }}
            className="navbar"
          >
            <img
              src="/ui-assets/images/close.svg"
              alt="close"
              style={{
                cursor: "pointer",
                height: "20px",
                width: "20px",
                padding: "5px 0 0 5px",
              }}
            />
          </div>
          <Carousel
            showThumbs={false}
            showStatus={false}
            autoPlay={false}
            dynamicHeight={false}
            showArrows={true}
          >
            <img className="content" src={src} alt="attachment" />
          </Carousel>
        </div>
      )}

    </div>
  );
};

const ChatContainer = ({ searchText, pChatid, pId = '', chatName, chatPriority, setAppData, communication, height = '', oldAppointment = false, dateTimeFormat, channelName = '', vidMsg = false }) => {
  // eslint-disable-next-line
  const [chatRender, setReply] = useState(false);
  const { chatGroup = {}, replyMsg = {} } = communication;

  const replyCallBack = (message) => {
    if (message) {
      setAppData('communication.replyMsg', {
        token: message.timetoken,
        reply: true,
        message: message.message.textmessage,
        name: message.message.user.Name,
        role: message.message.user.role,
        messagetype: message.message.messagetype
      });
      let localReply = {
        token: message.timetoken,
        reply: true,
        message: message.message.textmessage,
        name: message.message.user.Name,
        role: message.message.user.role,
        messagetype: message.message.messagetype
      };
      localStorage.setItem('localReply', JSON.stringify(localReply));
      setReply(true);
    } else {
      setAppData('communication.replyMsg', {
        token: '',
        reply: false,
        message: '',
        name: ''
      });
      localStorage.setItem('localReply', '');
      setReply(false);
    }

  }
  useEffect(() => {
    return async function () {
      // try{
      //   const patientDetail = await httpRequest({
      //     endPoint: `patient-service/v1/_search`,
      //     method: "post",
      //     instance: "instanceThree",
      //     requestBody: wrapRequestBody({ids: pId}, "_search"),
      //   });
      //   if(patientDetail.Patients){
      //     patientDetail.Patients[0].lastChatDate = new Date().getTime();
      //     patientDetail.Patients[0].userInfo.dob = Number(get(patientDetail, 'Patients[0].userInfo.dob', 0));
      //     let newBody = {"Patients":[patientDetail.Patients[0]]}

      //     await httpRequest({
      //       endPoint: `patient-service/v1/_update`,
      //       method: "post",
      //       instance: "instanceThree",
      //       requestBody: wrapRequestBody(newBody, "_update"),
      //   });
      //   }

      // }catch(err){
      // console.log('coming in error', err);
      // }
      if (!oldAppointment) {
        setAppData('communication.newMessages', []);
      }
      setAppData('communication.oldAppointment', []);
    }
    // eslint-disable-next-line
  }, [setAppData])

  return (
    <PubNubProvider client={pubnub}>
      <Chat
        searchText={searchText}
        pChatid={pChatid}
        chatName={chatName}
        chatPriority={chatPriority}
        setAppData={setAppData}
        prevMessages={chatGroup[chatName]}
        height={height}
        oldAppointment={oldAppointment}
        replyMsg={replyMsg}
        replyCallBack={replyCallBack}
        communication={communication}
        dateTimeFormat={dateTimeFormat}
        channelName={channelName}
        vidMsg={vidMsg}
      />
    </PubNubProvider>
  );
};

const mapStateToProps = ({ screenConfiguration }) => {
  const { preparedFinalObject = {} } = screenConfiguration;
  const { communication, mdmsData = {} } = preparedFinalObject;
  const { dateTimeFormat = {} } = mdmsData;
  return { communication, dateTimeFormat: { ...dateTimeFormat } };
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatContainer);