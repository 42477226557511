export default {
  typography: {
    // In Japanese the characters are usually larger.
    fontSize: 12,
  },
  palette: {
    primary: {
      main: "#089bab",
      contrastText: "#fff",
    }
  },
  overrides: {
    // Style sheet name ⚛️
    MuiCardContent: {
      // Name of the rule
      root: {
        // Some CSS
        padding: '8px 8px 8px 8px !important',
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: "OpenSansRegular"
      },
      h6: {
        // width: "192px",
        // height: "40px",
        fontFamily: "Poppins",
        fontSize: "32px",
        fontWeight: "bold",
        color: "#3a3a3a",
      }
    },
    MuiInputBase: {
      formControl: {
        height: "40px",
        borderRadius: "12px"
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "12px"
      },
      adornedEnd: {
        paddingRight: "0px",
        borderRadius: "12px",
      }
    },
    MuiTextField: {
      root: {
        width: "404px"
      }
    },
    MuiDialog: {
      paper: {
        borderRadius: "32px",
      },
      paperWidthSm: {
        maxWidth: "880px"
      },
      paperFullWidth: {
        width: "auto",
        height: "auto"
      }
    },
    MuiDialogTitle: {
      root: {
        padding: "32px 32px 0px 32px"
      }
    },
    MuiDialogContent: {
      root: {
        padding: "24px 32px 32px 32px"
      }
    },
    MuiStepConnector: {
      alternativeLabel: {
        left: "calc(-50% + 0px)",
      }
    },
    MuiStep: {
      horizontal: {
        display: "flex",
        alignItems: "flex-start",
        paddingLeft: "0px"
      }
    },
    MuiIconButton: {
       root: {
         padding: "0px"
       }
    },
    MuiPopover: {
      paper: {
        borderRadius: "8px",
        boxShadow: "0 4px 20px 0 rgba(3, 62, 68, 0.21)",
        backgroundColor: "#ffffff"
      }
    },
    MuiAccordionSummary: {
      content: {
        paddingLeft: "8px"
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: "0px",
        marginBottom: "0px"
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  }
}
