import React,{ useEffect, useState }  from "react";
import { withStyles,Grid } from '@material-ui/core';
import Select from "react-select";
import ChatContainer from '../Chat';
import Room from './room';

const styles = {
};

const style = {
  control: (base) => ({
    ...base,
    border: "2px solid #d7d7d7",
    // This line disable the blue border
    boxShadow: "0 !important",
    "&:hover": {
      border: "2px solid #d7d7d7",
    },
    width: 330,
    margin: '20px auto 10px',
    borderRadius: 12,
    fontSize: 15,
    color: "#333333",
    lineHeight: "24px",
    letterSpacing: 0,
  }),
};

const VideoCall = ({ endVideo, name, token,roomId, appointmentClinicians=[], channel, pChatid, communication }) => {
   
    const [view, toggleList] = useState('grid');
    const [viewChat, toggleChat] = useState(false);
    const [add, addPeople] = useState(false);
    const [clinician, setClinician] = useState('');
    const [clinicianArr, setClinicianArr] = useState([]);
    const [mute, muteAudio] = useState(false);
    const [showVideo, videoToggle] = useState(false);
    const [minMax, minimise] = useState(false);

    const removeClinicians = (uuid) => {
      let newArr = clinicianArr.filter(item => item.uuid !==uuid);
      setClinicianArr(newArr)
    }

    useEffect(() => {
        dragElement(document.getElementById("video-popup"));
    
        function dragElement(elmnt) {
          var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
          if (document.getElementById(elmnt.id + "header")) {
            /* if present, the header is where you move the DIV from:*/
            document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
          } else {
            /* otherwise, move the DIV from anywhere inside the DIV:*/
            elmnt.onmousedown = dragMouseDown;
          }
        
          function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
          }
        
          function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
          }
        
          function closeDragElement() {
            /* stop moving when mouse button is released:*/
            document.onmouseup = null;
            document.onmousemove = null;
          }
        }
    }, []);
    
    let newFilter = [];
    appointmentClinicians.forEach(item =>{
      let flag = true;
      clinicianArr.forEach(key => {
        if(key.uuid === item.uuid){
        flag = false;
        }
      });
      if(flag){
        newFilter.push(item)
    }
    });
    
      return(
        <div>
        <div  id='video-popup'  style={minMax?{display:'none'}:{}}>
        <Grid container className='videoContainer' style={viewChat?{borderTopRightRadius:0, borderBottomRightRadius:0}:{}}>
            <Grid item xs={12}>
            <Room roomName={name} token={token} view={view} mute={mute} showVideo={showVideo} roomId={roomId}/>
            <div className='bottomToolbar'>
            <div className='blueBackground vidPos' onClick={()=>videoToggle(!showVideo)}>
              {showVideo?
                <img src='ui-assets/images/video-off.svg'  alt="vid"  width='20px' height='20px'/>
              :
                <img src='ui-assets/images/showVideo.svg'  alt="vid"  width='30px' height='30px'/>
              }
            </div>
            <div className='blueBackground audPos' onClick={()=>muteAudio(!mute)}>
              {mute?
              <img src='ui-assets/images/audio-off.svg'  alt="aud"  width='20px' height='20px'/>
              :
              <img src='ui-assets/images/unmute.svg'  alt="aud"  width='30px' height='30px'/>
              }
              </div>
            <div className='endButton' onClick={()=>{endVideo()}}>
              <img src='ui-assets/images/call-end.svg'  alt="end"  width='20px' height='20px'/>
            </div>
              <div className='blueBackground peoplePos' onClick={()=>{addPeople(!add)}}>
                <img src='ui-assets/images/addPeople.svg'  alt="add"  width='20px' height='20px'/>
              </div>
              <div className='blueBackground chatPos' onClick={()=>{toggleChat(!viewChat)}}>
              <img src='ui-assets/images/blueChat.svg'  alt="chat"  width='20px' height='20px'/>
              </div>
              <div className='blueBackground maxPos' style={{opacity:'0.4', pointerEvents:'none'}} onClick={()=>{minimise(false)}}>
              <img src='ui-assets/images/max.svg'  alt="max"  width='20px' height='20px'/>
              </div>
              <div className='blueBackground minPos' onClick={()=>{minimise(true)}}>
              <img src='ui-assets/images/min.svg'  alt="min"  width='20px' height='20px'/>
              </div>
            </div>
            {view === 'grid' && 
            <div className='listView' onClick={()=>{toggleList('list')}}>
            <img src='ui-assets/images/list-view.svg'  alt="list"  width='20px' height='20px'/>
            </div>
            }
            {view === 'list' && 
            <div className='listView' onClick={()=>{toggleList('grid')}}>
            <img src='ui-assets/images/block-view.svg'  alt="list"  width='20px' height='20px'/>
            </div>
            }
            {add && 
            <div  className='menu addContainer'>
               <Select
                  placeholder="Select  Clinicians"
                  options={newFilter}
                  components={{
                      IndicatorSeparator: () => null,
                  }}
                  isSearchable={ false }
                  value={clinician}
                  onChange={(e)=>{
                    setClinicianArr([...clinicianArr,e])
                    setClinician(e)
                  }}
                  styles={style}
                  autosize={true}
                />
              {
                clinicianArr.map(item =>{
                  return(
                    <Grid container key={item.uuid} className='gridContainer'>
                      <Grid item xs={4} style={{fontFamily:'OpenSansRegular',color:' #333333', textAlign:'center',fontSize:'15px', overflow:'hidden'}}>
                        {item.value}
                      </Grid>
                      <Grid item xs={4} style={{fontFamily:'OpenSansRegular',color:'#636363', textAlign:'right',fontSize:'15px'}}>
                        {item.roles}
                      </Grid>
                      <Grid item xs={2} style={{alignSelf:'center', textAlign:'end'}}>
                        <img 
                          src='ui-assets/images/delete-grey.svg'  
                          alt="del" 
                          onClick={()=>removeClinicians(item.uuid)} 
                          style={{cursor:'pointer'}} 
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <div className='shape'>
                        <img 
                          src='ui-assets/images/add-people.svg'  
                          alt="addPeople" 
                          onClick={()=>addPeople(false)} 
                          style={{cursor:'pointer'}} 
                        />
                        </div>
                      </Grid>
                    </Grid>
                  )
                })
              }
            </div>
            }
           
            </Grid>
            
        </Grid>
        {viewChat && 
        <Grid container className='videoChat'>
          <ChatContainer 
            height='455' 
            pChatid={pChatid}
            chatName={name}
            chatPriority='no'
            channelName={channel}
            communication={communication}
            vidMsg={true}
          />
        </Grid>
        }
        </div>
        <Grid container style={minMax?{}:{display:'none'}}>
        <div className='minimisedBar'>
            <div className='blueBackground' style={{margin:'0 20px'}} onClick={()=>videoToggle(!showVideo)}>
              {showVideo?
                <img src='ui-assets/images/video-off.svg'  alt="vid"  width='20px' height='20px'/>
              :
              <img src='ui-assets/images/showVideo.svg'  alt="vid"  width='35px' height='35px'/>
              }
            </div>
            <div className='blueBackground' style={{margin:'0 20px'}} onClick={()=>muteAudio(!mute)}>
              {mute?
              <img src='ui-assets/images/audio-off.svg'  alt="aud"  width='20px' height='20px'/>
              :
              <img src='ui-assets/images/unmute.svg'  alt="aud"  width='30px' height='30px'/>
              }
            </div>
           
              {/* <div className='blueBackground' onClick={()=>{addPeople(!add)}}>
                <img src='ui-assets/images/addPeople.svg'  alt="add"  width='20px' height='20px'/>
              </div> */}
              <div className='blueBackground' style={{margin:'0 20px', opacity:'0.4', pointerEvents:'none'}} onClick={()=>{toggleChat(!viewChat)}}>
               <img src='ui-assets/images/blueChat.svg'  alt="chat"  width='20px' height='20px'/>
              </div>
              <div className='blueBackground' style={{margin:'0 20px'}} onClick={()=>{minimise(false)}}>
              <img src='ui-assets/images/max.svg'  alt="max"  width='20px' height='20px'/>
              </div>
              <div className='blueBackground' style={{margin:'0 20px', opacity:'0.4', pointerEvents:'none'}} onClick={()=>{minimise(true)}}>
              <img src='ui-assets/images/min.svg'  alt="min"  width='20px' height='20px'/>
              </div>
              <div  className='blueBackground' style={{cursor:'pointer',background:'red',margin:'0 20px' }} onClick={()=>{endVideo()}}>
              <img src='ui-assets/images/call-end.svg'  alt="end"  width='20px' height='20px'/>
              </div>
            </div>
        </Grid>
        </div>
        )  
}
export default withStyles(styles)(VideoCall);