import React from "react";
import { Route, Redirect } from "react-router";
import Loadable from "react-loadable";
import Loading from "../ui-molecules/Loading";
import { localStorageGet } from "../ui-utils/commons";

const Login = Loadable({
  loader: () => import("../ui-pages/Login"),
  loading: Loading
});

const Dashboard = Loadable({
  loader: () => import("../ui-pages/UserHome/index"),
  loading: Loading
});

const ResetPassword = Loadable({
  loader: () => import("../ui-pages/Login/components/ResetPassword/ResetPassword"),
  loading: Loading
});

const ForgotPassword = Loadable({
  loader: () => import("../ui-pages/Login/components/ForgotPassword/ForgotPassword"),
  loading: Loading
});

const SetupAccount = Loadable({
  loader: () => import("../ui-pages/Login/components/SetupAccount/SetupAccount"),
  loading: Loading
});

const AddPatient = Loadable({
  loader: () => import("../ui-pages/UserHome/components/Content/Administration/addPatient"),
  loading: Loading
});

const PatientSummary = Loadable({
  loader: () => import("../ui-pages/UserHome/components/Content/Administration/components/TabContent/Patients/patientSummary"),
  loading: Loading
})

const ClinicianSummary = Loadable({
  loader: () => import("../ui-pages/UserHome/components/Content/Administration/components/TabContent/Clinician/clinicianSummary"),
  loading: Loading
})

const Admin = Loadable({
  loader: () => import("../ui-pages/UserHome/components/Content/Administration/admin"),
  loading: Loading
})

const Terms = Loadable({
  loader: () => import("../ui-pages/Login/components/Terms"),
  loading: Loading
})

const Privacy = Loadable({
  loader: () => import("../ui-pages/Login/components/Privacy"),
  loading: Loading
})

const Mprivacy = Loadable({
  loader: () => import("../ui-pages/Login/components/Mprivacy"),
  loading: Loading
})

const Mterms = Loadable({
  loader: () => import("../ui-pages/Login/components/Mterms"),
  loading: Loading
})

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    localStorageGet("loggedIn") === "true" && localStorageGet("userInfo", "OAuthDetails.createdAt") 
      ? <Component {...props} />
      : <Redirect to='/' />
  )} />
)

/**
 * 
 * @returns open and private routes
 * OPEN --------> "/", "/login", "/terms-conditions", "/mobile-policy", "/mobile-terms", "/privacy-policy", "reset-password", "forgot-password", "/setup-account"
 * REDIRECTS ---> "/", "/login", "/forgot-password"
 * PRIVATE -----> "/addPatient", "/patient-summary", "user-home/admin", "user-home/administration/patient-summary", "user-home/administration/clinician-summary"
 */
const MainRoutes = () => {
  return (
    <div>
      {/* redirects for new tab */}
      <Route exact path="/">
        {localStorageGet("loggedIn") === "true" ? <Redirect to='/user-home' /> : <Redirect to='/' />}
      </Route>
      <Route exact path="/login">
        {localStorageGet("loggedIn") === "true" ? <Redirect to='/user-home' /> : <Redirect to='/login' />}
      </Route>
      <Route exact path="/forgot-password">
        {localStorageGet("loggedIn") === "true" ? <Redirect to='/user-home' /> : <Redirect to='/forgot-password' />}
      </Route>

      {/* routes */}
      <Route exact path="/" component={Login} />
      <Route path="/login" component={Login} />
      <Route path="/terms-conditions" component={Terms} />
      <Route path="/mobile-policy" component={Mprivacy} />
      <Route path="/mobile-terms" component={Mterms} />
      <Route path="/privacy-policy" component={Privacy} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/setup-account" component={SetupAccount} />
      <PrivateRoute path="/user-home" component={Dashboard} />
      <PrivateRoute path="/addPatient" component={AddPatient} />
      <PrivateRoute path="/patient-summary" component={PatientSummary} />
      <PrivateRoute path="/user-home/admin" component={Admin} />
      <PrivateRoute exact path="/user-home/administration/patient-summary" component={PatientSummary} />
      <PrivateRoute exact path="/user-home/administration/clinician-summary" component={ClinicianSummary} />
    </div>
  )
}

export default MainRoutes;
