import set from "lodash/set";

export const validateField = field => {
  const {
    required,
    pattern,
    minLength,
    maxLength,
    minValue,
    maxValue,
    visible,
    isDOB
  } = field;

  if (visible !== undefined && !visible) {
    return { isFieldValid: true, errorText: "" };
  }

  const value = field.value
    ? typeof field.value === "string"
      ? field.value.trim()
      : field.value
    : null;
  let errorText = "",
    isFieldValid = true,
    fieldLength = 0;

  if (required && !value) {
    isFieldValid = false;
    errorText = field.requiredMessage || "Required";
  }

  if (value) {
    fieldLength = value.length;
  }

  if (
    isFieldValid &&
    fieldLength &&
    pattern &&
    !new RegExp(pattern).test(value)
  ) {
    isFieldValid = false;
  }
  if (
    isFieldValid &&
    minLength &&
    maxLength &&
    !(fieldLength >= minLength && fieldLength <= maxLength)
  ) {
    isFieldValid = false;
  }
  if (
    isFieldValid &&
    minValue &&
    maxValue &&
    !(value >= minValue && value <= maxValue)
  ) {
    isFieldValid = false;
  }

  if (isDOB) {
    if (value) {
      let currentDate = new Date().getTime();
      let ownerDOB = new Date(value).getTime();
      if (ownerDOB > currentDate) {
        isFieldValid = false;
      }
    }
  }

  errorText = !isFieldValid
    ? errorText.length
      ? errorText
      : field.errorMessage || "Invalid field"
    : "";

  return { isFieldValid, errorText };
};



export const updateObjectWithComponentJsonPath = (
  screenConfig,
  componentJsonpath,
  property,
  value
) => {
  set(screenConfig, `${componentJsonpath}.${property}`, value);
  return screenConfig;
};

export const prepareFinalObject = (preparedFinalOject, jsonPath, value) => {
  set(preparedFinalOject, jsonPath, value);
  return preparedFinalOject;
};

export const patientPrescriptions = (therapy = []) => {
  let therapyArray = [];
  therapy.length > 0 &&
    therapy.map((pre, key) => {
      const {
        therapyType = "",
        bagStrengths = [],
        fillVolume = "",
        duration = "",
        totalTherapyVolume = "",
        notes = "",
        lastFluidType = "",
        lastFluidConcerntration = "",
        lastFluidVolume = "",
        uuid = "",
        noOfExchange = "",
        exchanges = [],
        tidalVolume = "",
      } = pre || {};
      let bagStrengthsArray = [];
      let capdChanges = [];
      let therapy_uuid = {};
      let lastFillObject = {};
      therapy.length > 0 &&
        therapy.forEach((therap_y) => {
          therapy_uuid[therap_y.therapyType] = therap_y.uuid;
        });
      bagStrengths.length > 0 &&
        bagStrengths.forEach((bag, key1) => {
          const { fluidType = "", concentration = "", volume = "" } =
            bag || {};
          bagStrengthsArray.push({
            fluidType: fluidType,
            concentration: Number(concentration),
            volume: Number(volume),
          });
        });
      exchanges.length > 0 &&
        exchanges.forEach((capd, key2) => {
          const { fluidType = "", concentration = "", duration = "" } =
            capd || {};
          capdChanges.push({
            fluidType: fluidType,
            concentration: Number(concentration),
            duration: Number(duration)
          });
        });
      const lastFill_fluidType = lastFluidType !== "null" ? lastFluidType : null;
      lastFillObject = lastFill_fluidType && {
        lastFluidType: lastFill_fluidType,
        lastFluidConcerntration: lastFluidConcerntration,
        lastFluidVolume: Number(lastFluidVolume),
      };
      switch (therapyType) {
        case "APD":
          therapyArray.push({
            uuid: uuid || null,
            therapyType: therapyType,
            fillVolume: Number(fillVolume),
            duration: Number(duration),
            tidalVolume: 50,
            totalTherapyVolume: Number(totalTherapyVolume),
            bagStrengths: bagStrengthsArray,
            ...lastFillObject,
            notes: notes,
          });
          break;
        case "APD_TIDAL":
          therapyArray.push({
            uuid: uuid || null,
            therapyType: therapyType,
            fillVolume: Number(fillVolume),
            tidalVolume: Number(tidalVolume),
            duration: Number(duration),
            totalTherapyVolume: Number(totalTherapyVolume),
            bagStrengths: bagStrengthsArray,
            ...lastFillObject,
            notes: notes,
          });
          break;
        case "CAPD":
          therapyArray.push({
            uuid: uuid || null,
            therapyType: therapyType,
            fillVolume: Number(fillVolume),
            noOfExchange: Number(noOfExchange),
            exchanges: capdChanges,
            ...lastFillObject,
            notes: notes,
          });
          break;
        default:
          break;
      }
      return {
        therapyArray,
      };
    });
  return [...therapyArray];
};
